import React from 'react';
import { ErrorMessage } from 'formik';

const ReactstarpFormikInput = (
    {
        field: { ...fields },
        form: { touched, errors, setFieldValue, ...rest },
        stateName,
        ...props
    }) => (
    <React.Fragment>
        <select {...props} {...fields}
            className={'form-control' + (touched[fields.name] && errors[fields.name] ? ' is-invalid' : '')}
            style={{ backgroundImage: 'none' }}
            // invalid={Boolean(touched[fields.name] && errors[fields.name])} selected={props.value === item.value}
            onChange={(event) => {
                setFieldValue(fields.name, event.target.value);
                props.onChange(event.target.value, fields.name);
            }}>
            {props.option.map((item, index) =>
                <option key={index} value={item.value}
                    selected={(item.label === localStorage.getItem('stateName')) ? true
                        : (item.label === localStorage.getItem('cityName')) ? true
                            : false} >{item.label}
                </option>)}
        </select>
        <ErrorMessage name={[fields.name]} component="div" className="invalid-feedback" />
    </React.Fragment>
);

export default ReactstarpFormikInput;