import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import './core/axios.interceptor';
import AppContainer from './AppContainer';

export default function App() {
  return (
    <React.Fragment>
      <AppContainer />
    </React.Fragment>
  );
}
