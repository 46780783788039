import * as Yup from 'yup';
import { translateViaApi } from '../../../../common/translator';

const userDetails = () => {
    return {
        userName: '',
        userPwd: '',
        confirmPassword: '',
        otp: ''
    }
}

// Reset password form validation schema
export const formValidationSchema = (res) => Yup.object().shape({
    userPwd: Yup.string()
        .required(res?.filter(i => i.key === "ResetPassword.PasswordRequiredError")[0]?.value)
        // .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/, { message: translateViaApi(RegConstants.ResetPasswordInterfaceLabels.PasswordInvalidError), excludeEmptyString: false })
        .matches('^[^\\s]+$', { message: res?.filter(i => i.key === "ResetPassword.PasswordInvalidError")[0]?.value, excludeEmptyString: false })
        .max(15)
        .min(6, res?.filter(i => i.key === "Validation.PasswordMinimumLength")[0]?.value),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('userPwd'), null], res?.filter(i => i.key === "ResetPassword.PasswordMismatchError")[0]?.value)
        .required(res?.filter(i => i.key === "ResetPassword.ConfirmPasswordIsRequired")[0]?.value)
        .max(15)
        .min(6, res?.filter(i => i.key === "Validation.PasswordMinimumLength")[0]?.value),
    otp: Yup.string()
        .required(res?.filter(i => i.key === "Validation.OtpIsRequired")[0]?.value)
        .max(6, res?.filter(i => i.key === "Validation.OtpIsInvalid")[0]?.value)
        .min(6, res?.filter(i => i.key === "Validation.OtpIsInvalid")[0]?.value),
});

// States
const resetPasswordState = (function states() {
    return {
        userDetails: userDetails(),
        formValidationSchema: formValidationSchema(),
        isOtpNull: false,
        userLanguageApiLabels: [],
        isPageLoaded: false,
        isPasswordStrength: false,
        passwordStrength: {},
        isOtpResent: false,
    }
})()

export default resetPasswordState;


