import * as Yup from 'yup';
import { translateViaApi } from '../../../common/translator';
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';

const forgotPasswordDetails = () => {
    return {
        userName: '',
    }
}

//regular expressions for validating text fields
const regexEmail = /^(?!\.)(?!.*\.$)(?!.*?\.\.)(?!.*?\.@)([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
const regexMobileNumber = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// Forgot password form validation schema
export const formValidationSchemaMobile = (res) => Yup.object().shape({
    mobileNumber: Yup.string()
        .matches(regexMobileNumber, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginMobileNumberInvalid)[0]?.value, excludeEmptyString: false })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginMobileNumberIsRequired)[0]?.value)
        .min(10, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginMobileNumberInvalid)[0]?.value)
        .max(10, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginMobileNumberInvalid)[0]?.value),
});

// Forgot password form validation schema with email
export const formValidationSchemaEmail = (res) => Yup.object().shape({
    userName: Yup.string()
        .matches(regexEmail, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginEmailIsInvalid)[0]?.value, excludeEmptyString: false })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginEmailIsRequired)[0]?.value),
});

const ForgotPasswordState = (function states() {
    return {
        forgotPasswordDetails: forgotPasswordDetails(),
        formValidationSchemaMobile: formValidationSchemaMobile(),
        formValidationSchemaEmail: formValidationSchemaEmail(),
        headerName: 'Forget Password',
        countryList: [],
        countryCode: '',
        userLanguageApiLabels: [],
        isPageLoaded: false,
        isEmailValid: false
    }
})()

export default ForgotPasswordState;