import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './SubHeaderModule.css';
import VooySubHeader from '../../../asset/headerimages/VooySubHeader.png';
import { useLocation } from 'react-router-dom';
import VooyHeader from '../../../asset/headerimages/VooyHeader.png'
import { RegConstants } from "../registration-constants/RegistrationConstants";

const SubHeaderComponent = (props) => {
    const [state, setState] = useState({ userLanguageApiLabels: [], isPageLoaded: false });

    useEffect(() => {
        let result = JSON.parse(localStorage.getItem('languageApiLabels'));
        setState(() => ({
            userLanguageApiLabels: result,
            isPageLoaded: true
        }))
    }, []);

    // It is used to get current pathname
    let location = useLocation();

    return (
        <React.Fragment>
            {
                (location.pathname === "/userdetail/vehicle-info") ?
                    <div className="sub_header_vehicle_section">
                        <img className="vooyLogo_Header_part" src={VooySubHeader} alt="Sub Header" />
                        <h4 className="subHeader_Heading main_heading_top">{state.isPageLoaded && state.userLanguageApiLabels.filter(i => i.key === 'Header.RegisterYourTransport')[0].value}</h4>
                    </div>
                    :
                    (location.pathname === "/userdetail/home") ?
                        <div >
                            {
                                localStorage.getItem('languageCode') === RegConstants.LanguageCodeCD.mexican ?
                                    <div className="sub_header_home_spanish">
                                    </div> :
                                    <div className="sub_header_home_english">
                                    </div>
                            }
                        </div>
                        :
                        location.pathname === "/my-rides" ? ""
                            :
                            <div className="sub_header_section">
                                <img className="vooyLogo_Header_part" src={VooyHeader} alt="Header" />
                            </div>
            }
        </React.Fragment>
    )
}

export default SubHeaderComponent;