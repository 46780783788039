import React, { Component } from 'react';
import './MyRides.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";
import states from './MyRides.state';
import * as rideService from '../../../service/auth/my-rides/MyRides.Service';
import Currency from '../../../asset/images/currency.png'
import TaxProfile from '../tax-profile/TaxProfile';
import StarRatings from 'react-star-ratings';
import DiamondIcon from '../../../asset/images/DiamondIcon.png'
import * as moment from 'moment'
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';
import * as commonMethods from '../../common/common-methods/CommonMethods';

class MyRides extends Component {
    constructor(props) {
        super(props);
        this.state = states;
    }

    componentDidMount = () => {
        let result = JSON.parse(localStorage.getItem('languageApiLabels'));
        this.setState({ userLanguageApiLabels: result }, () => { })
        this.setState({ isPageLoaded: true });

        localStorage.removeItem('pickupLat');
        localStorage.removeItem('pickupLon');
        localStorage.removeItem('dropLat');
        localStorage.removeItem('dropLon');
        if (this.props.location.isVeiwTaxProfile === true) {
            this.setState({ isVeiwTaxProfile: true, isVeiwRides: false });
        }
        else {
            let token = localStorage.getItem('token')
            if (token)
                this.getRides();
            else {
                this.props.history.push({
                    pathname: '/login-user-mobile',
                })
            }
        }
    }

    // It fetches the rides
    getRides = () => {
        let dates = this.getFilterDates();

        rideService.getAllRides(5, 2, RegConstants.RideOrderStatusCD.Completed, dates.dateOfToday, dates.date30DaysBefore).then((result) => {
            if (result) {
                this.setState({ rides: result.data });
            }
        });
    }

    // It fetches the rides which are completed between today and 30 days before 
    filterRides = (e) => {
        let { value } = e.target;
        let dates = this.getFilterDates();

        rideService.getAllRides(5, value, RegConstants.RideOrderStatusCD.Completed, dates.dateOfToday, dates.date30DaysBefore).then((result) => {
            if (result) {
                this.setState({ rides: result.data });
            }
        });
        if (value === 2)
            this.setState({ isShowPreviousRides: true });
        else
            this.setState({ isShowPreviousRides: false });
    }

    // It returns the todays date and the date of 30 days ago
    getFilterDates = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        let dateOfToday = mm + '/' + dd + '/' + yyyy;
        let date30DaysBefore = moment(today).subtract(30, 'days');
        date30DaysBefore = moment(date30DaysBefore).format('MM/DD/YYYY');

        return { dateOfToday, date30DaysBefore };
    }

    // It displays the option for creating tax profile(invoice)
    showTaxProfile = () => {
        this.setState({ isVeiwTaxProfile: true, isVeiwRides: false, rides: [] });
    }

    // It fetches the rides
    showMyRides = () => {
        this.setState({ isVeiwRides: true, isVeiwTaxProfile: false, isShowPreviousRides: true });
        if (this.state.ride === undefined) {
            this.getRides();
        }
    }

    // It expands and collapses the ride div
    expandRideDeatils = (e) => {
        let event = e.target.dataset.event;
        this.setState({
            expandElementId: this.state.expandElementId === event ? 0 : event
        });
    }

    // It routes to the ride-detail page, where all ride details can be checked
    showRideDetails = (ride) => {
        let rideDetail = [];
        rideDetail.push(ride);

        this.props.history.push({
            pathname: '/ride-details',
            rideDetail: rideDetail
        });
    }

    render() {
        const { expandElementId } = this.state;
        return (
            <div className="main_section">
                <div className="sub-main-section">
                    <div className="form-section-width">
                        <div className="container collapse-container form-container-width">
                            <div className='toggle-buttons-div'>
                                <button className={this.state.isVeiwTaxProfile ? 'tax-button' : 'toggle-buttons'} onClick={() => this.showTaxProfile()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.TaxProfileButton')[0].value}</button>
                                <button className={this.state.isVeiwRides ? 'ride-button' : 'toggle-buttons'} onClick={() => this.showMyRides()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.MyRideButton')[0].value}</button>
                            </div>
                            {
                                this.state.isVeiwTaxProfile ?
                                    <TaxProfile />
                                    :
                                    <div className="rides-div">
                                        <div className="select-div">
                                            <i class="fas fa-filter filter-icon"></i>
                                            <select id="filterRides" name="filterRides" onChange={this.filterRides}>
                                                {/* <option disabled={true}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.FilterRides')[0].value}</option> */}
                                                {/* <option value="1">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.Upcoming')[0].value}</option> */}
                                                <option selected={this.state.isShowPreviousRides} value="2">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.Previous')[0].value}</option>
                                                {/* <option value="3">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.Shared')[0].value}</option> */}
                                            </select>
                                        </div>
                                        {
                                            this.state.rides.map((element, index) => {
                                                return (
                                                    <Card key={index} className="card rounded card">
                                                        <CardHeader
                                                            className={expandElementId === element.uId ? "expanded-card" : "card"}
                                                            data-event={(index = element.uId)}
                                                        >
                                                            <div className="collapse d-flex">
                                                                <div className="ride-date-div">
                                                                    <p ><i className={expandElementId === element.uId ? "fas fa-chevron-down fa-rotate-45" : "fas fa-chevron-right chevron-right"} data-event={index} onClick={(e) => this.expandRideDeatils(e)}></i>
                                                                        {/* {moment(element.createdDateTime).format('DD/MMM/YYYY - hh:mm:ss A')} */}
                                                                        {moment.utc(element.orderDate).local().format('DD/MM/YYYY hh:mm:ss A')}
                                                                    </p>
                                                                    <p >{element.currencyCode === "INR" ? <i class="fas fa-rupee-sign doller-icon"></i> : <i className="fas fa-dollar-sign doller-icon"></i>}{element.totalAmount}</p>
                                                                </div>
                                                                <div className="ride-address-div">
                                                                    <p >{element.pickupLocation}</p>
                                                                    <p ><img src={Currency} className="currency-icon" alt="currency"></img>{element.paymentMode}</p>
                                                                </div>
                                                            </div>
                                                        </CardHeader>
                                                        <Collapse isOpen={expandElementId === index}>
                                                            <CardHeader className="expanded-card card-padding">
                                                                <div className="ride-date-div">
                                                                    <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.YourRideWith')[0].value}{element.senderName}</p>
                                                                </div>
                                                                <CardBody className="card-body pickup-drop-card">
                                                                    <div>
                                                                        <div className="pickup-div">
                                                                            <i className="fas fa-square-full d-flex start-location-icon"></i>
                                                                            <div>
                                                                                <p className="card-text">{element.pickupLocation}</p>
                                                                                <p>{moment.utc(element.orderStatuses.filter(i => i.orderStatusCD === RegConstants.RideOrderStatusCD.Pickup)[0].createdDateTime).local().format('hh:mm:ss A')}</p>
                                                                                {/* <p className="card-text">{element.orderDate}</p> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="vertical-line"> </div>
                                                                        <div className="drop-location-div">
                                                                            <img src={DiamondIcon} alt="Diamond Icon"></img>
                                                                            <div >
                                                                                <p className="card-text">{element.dropLocation}</p>
                                                                                <p>{moment.utc(element.orderStatuses.filter(i => i.orderStatusCD === RegConstants.RideOrderStatusCD.Drop)[0].createdDateTime).local().format('hh:mm:ss A')}</p>
                                                                                {/* <p className="card-text">{element.orderDate}</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </CardHeader>
                                                        </Collapse>
                                                        <Collapse isOpen={expandElementId === index}>
                                                            <CardHeader className="expanded-card margin-block-end rating-download-div"  >
                                                                <CardBody className="card-body rating-invoice-div">
                                                                    <div className="rating-div">
                                                                        <p className="card-text">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.Rate')[0].value}</p>
                                                                        <StarRatings
                                                                            rating={element.senderRatingValue}
                                                                            starRatedColor="#FFD700"
                                                                            numberOfStars={5}
                                                                            name='rating'
                                                                            starDimension="30px"
                                                                            starSpacing="2px"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={() => commonMethods.downloadInvoicePDF(element)}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.ObtainInvoice')[0].value}</button>
                                                                        <i class="fas fa-arrow-down arrow-icon"></i>
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={() => commonMethods.downloadInvoiceXML(element)}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.ObtainInvoiceXML')[0].value}</button>
                                                                        <i class="fas fa-arrow-down arrow-icon"></i>
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={() => this.showRideDetails(element)}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.Details')[0].value}</button>
                                                                        <i class="fas fa-arrow-right arrow-icon"></i>
                                                                    </div>
                                                                </CardBody>
                                                            </CardHeader>
                                                        </Collapse>
                                                    </Card>
                                                );
                                            })
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}

export default MyRides;