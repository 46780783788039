import React, { Component } from 'react';
import { Route, Redirect, Switch, HashRouter as Router, withRouter } from 'react-router-dom';
import LoginUserWithMobile from './component/auth/login/LoginUserWithMobile';
import LoginUserWithEmail from './component/auth/login/LoginUserWithEmail';
import RegistrationRoutingComponent from './component/auth/registration-routing/RegistrationRoutingComponent';
import ForgotPassword from './component/auth/forgot-password/ForgotPassword';
import ResetPassword from './component/auth/forgot-password/reset-password/ResetPassword';

class AppRouting extends Component {
    render() {
        let routes = (
            <Router>
                <Switch>
                    <Route exact path="/login-user-mobile" component={LoginUserWithMobile} />
                    <Route exact path="/login-user-email" component={LoginUserWithEmail} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    {/* <Route exact path='/' component={LoginUserWithMobile} /> */}
                    <Route path='/' component={RegistrationRoutingComponent} />
                    <Redirect to='/login-user-mobile' />
                </Switch>
            </Router>
        )
        return (
            <React.Fragment>
                {routes}
            </React.Fragment>
        );

    }
}
export default withRouter(AppRouting);