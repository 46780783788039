import { axios } from "../../../core/axios.interceptor";
import { tokenAxios } from "../../../core/TokenAPIInterceptor";

export async function checkUserProfileStatus() {
    const response = await axios.get(`User/profile`);
    return response.data;
}

export async function login(loginDetail) {
    const response = await tokenAxios.post('connect/token', loginDetail);
    return response.data;
}

// forget password
export async function forgetPassword(forgotPasswordDetails) {
    const response = await tokenAxios.post('user/forgot-password', forgotPasswordDetails);
    return response.data;
}
// resetPassword
export async function changePassword(newPasswordDetails) {
    const response = await tokenAxios.post('user/reset-password', newPasswordDetails);
    return response.data;
}

// resendDriverOOTP 
export async function resendDriverOOTP(userName) {
    const response = await axios.post(`User/registration/send-otp/driver/` + userName);
    return response.data;
}

export async function forgotPassword(forgotPasswordDetails) {
    const response = await tokenAxios.post('user/forgot-password', forgotPasswordDetails);
    return response;
}

export async function resetPassword(newPasswordDetails) {
    const response = await tokenAxios.post('user/reset-password', newPasswordDetails);
    return response;
}