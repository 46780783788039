import { toast } from 'react-toastify';
import * as rideService from '../../../service/auth/my-rides/MyRides.Service'
import { createHashHistory } from 'history';
import { RegConstants } from '../registration-constants/RegistrationConstants';
import * as loginService from '../../../service/auth/login/Login.Service';
import jwtDecode from 'jwt-decode';

// it prevents the user from entering alphabatic values 
export function updateNumber(event) {
    if (event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 9
        || event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 36 || event.keyCode === 35) {

    } else if (event.keyCode < 48 || event.keyCode > 57) {
        if (event.keyCode < 96 || event.keyCode > 105) {
            event.preventDefault();
        }
    }
}

// shows or hides the password field text
export function showHidePassword(event) {
    var x = document.getElementById("userPwd");
    var y = document.getElementById("password");
    if (x !== null ? x.type === "password" : y.type === "password") {
        x !== null ? x.type = "text" : y.type = "text";
        event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';

    } else {
        x !== null ? x.type = "password" : y.type = "password";
        event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
    }
}

// shows or hides the confiem password field text
export function showHideConfirmPassword(event) {
    var x = document.getElementById("confirmPassword");
    if (x.type === "password") {
        x.type = "text";
        event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';

    } else {
        x.type = "password";
        event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
    }
}

// it removes the async storage
export function removeAsyncStorageToken() {
    localStorage.removeItem('stateName');
    localStorage.removeItem('cityName');
    localStorage.removeItem('token');
    localStorage.removeItem('pickupLat');
    localStorage.removeItem('pickupLon');
    localStorage.removeItem('dropLat');
    localStorage.removeItem('dropLon');
    localStorage.removeItem('refresh_token');
}

// it removes the async storage
export function removeAsyncToken() {
    localStorage.removeItem('stateName');
    localStorage.removeItem('cityName');
    localStorage.removeItem('pickupLat');
    localStorage.removeItem('pickupLon');
    localStorage.removeItem('dropLat');
    localStorage.removeItem('dropLon');
}

// This function is used to check password strength by testing with regex string
export function checkPasswordStrength(password) {
    const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const mediumPasswordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    if (strongPasswordRegex.test(password)) {
        return {
            strength: 'Excellent',
            className: 'strong-password'
        }
    } else if (mediumPasswordRegex.test(password)) {
        return {
            strength: 'Medium',
            className: 'medium-password'
        }
    } else {
        return {
            strength: 'Low',
            className: 'low-password'
        }
    }
}

// It downloads the invoice in pdf format
export function downloadInvoicePDF(ride) {
    let result = JSON.parse(localStorage.getItem('languageApiLabels'));
    rideService.generateInvoice(ride.uId).then((response) => {
        if (response.hasError === false) {
            rideService.downloadInvoice(ride.uId).then((res) => {
                if (res) {
                    if (res.error === true) {
                        toast.error(result.filter(i => i.key === 'MyRides.InvoiceNotGenerated')[0].value);
                    } else if (res.error === false) {
                        const pdfLinkSource = `data:application/pdf;base64,${res.archivos.file_PDF}`;
                        const pdfDownloadLink = document.createElement("a");
                        const pdfFileName = "invoice.pdf";
                        pdfDownloadLink.href = pdfLinkSource;
                        pdfDownloadLink.download = pdfFileName;
                        pdfDownloadLink.click();
                        toast.success(result.filter(i => i.key === 'MyRides.InvoiceIsDownloading')[0].value);
                    }
                }
            })
        }
    })
}

// It downloads the invoice in XML format
export function downloadInvoiceXML(ride) {
    let result = JSON.parse(localStorage.getItem('languageApiLabels'));
    rideService.generateInvoice(ride.uId).then((response) => {
        if (response.hasError === false) {
            rideService.downloadInvoice(ride.uId).then((res) => {
                if (res) {
                    if (res.error === true) {
                        toast.error(result.filter(i => i.key === 'MyRides.InvoiceNotGenerated')[0].value);
                    } else if (res.error === false) {
                        const xmlLinkSource = `data:Application/xml;base64,${res.archivos.file_XML}`;
                        const xmlDownloadLink = document.createElement("a");
                        const xmlFileName = "invoice.xml";
                        xmlDownloadLink.href = xmlLinkSource;
                        xmlDownloadLink.download = xmlFileName;
                        xmlDownloadLink.click();
                        toast.success(result.filter(i => i.key === 'MyRides.InvoiceIsDownloading')[0].value);
                    }
                }
            })
        }
    })
}

const history = createHashHistory();

// if token is expired then a new token will be retrieved by the refresh token 
export async function getNewToken() {

    let refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
        history.push('/login-user-mobile');
    }
    else {
        let formData = new FormData();
        let token = localStorage.getItem('token');
        let decodedToken = '';
        if (token)
            decodedToken = jwtDecode(token);

        formData.append('grant_type', 'refresh_token');
        formData.append('client_secret', RegConstants.FormDataEmailCD.client_secret);
        formData.append('client_id', decodedToken.client_id);
        formData.append('refresh_token', refreshToken);

        localStorage.setItem('isTokenRefreshed', true);

        const newToken = await loginService.login(formData).then(async (refreshTokenResponse) => {

            // get new token again 
            // setTimeout(() => {
            //     getNewToken();
            // }, (refreshTokenResponse.expires_in - 30) * 1000);

            localStorage.setItem('token', refreshTokenResponse.access_token);
            localStorage.setItem('refresh_token', refreshTokenResponse.refresh_token);
            localStorage.removeItem('isTokenRefreshed');
            return true;
        })
            .catch(async (err) => {
                if (err) {
                    if (localStorage.getItem('token')) {
                        let partnerLanguageApiLabels = JSON.parse(localStorage.getItem('languageApiLabels'));
                        toast.error(partnerLanguageApiLabels.filter(i => i.key === 'Validation.SessionExpired')[0].value);
                    }
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('isTokenRefreshed');
                    history.push({ pathname: '/login-user-mobile', })
                }
            });

        return newToken;
    }
}

// it calculates the dynamic mobile length validation
export const getMinMaxMobileLength = (masterApiData, dialCode) => {
    let minMobileLength = Number(masterApiData.filter(i => i.key === 'Validation.MobileNoMinLength')[0]?.value);
    let maxMobileLength = Number(masterApiData.filter(i => i.key === 'Validation.MobileNoMaxLength')[0]?.value);
    let countryCode = dialCode.trim().substring(1);

    minMobileLength = minMobileLength - countryCode.length;
    maxMobileLength = maxMobileLength - countryCode.length;

    return { minMobileLength: minMobileLength, maxMobileLength: maxMobileLength }
}