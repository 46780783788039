import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HeaderModule.css';
import VooyHeader from '../../../asset/headerimages/VooyHeader.png'
import { withRouter } from 'react-router';
import { removeAsyncStorageToken } from '../common-methods/CommonMethods';

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { userLanguageApiLabels: [], isPageLoaded: false };
    }

    componentDidMount = () => {
        let result = JSON.parse(localStorage.getItem('languageApiLabels'));
        this.setState({ userLanguageApiLabels: result }, () => { })
        this.setState({ isPageLoaded: true });
    }

    // Logouts the user, removes credentials
    logout = () => {
        removeAsyncStorageToken();
        this.props.history.push({
            pathname: '/login-user-mobile',
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="Header_Section">
                    <div className="logo-ride-partner-div">
                        <img className="vooyLogo_Header" src={VooyHeader} alt="VooyRidePsrtner" />
                    </div>
                    <div className="links-div">
                        <a href="https://vooyapp.com.mx/" >{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'Header.Start')[0].value}</a>
                        <a href="https://vooyapp.com.mx/que-es-vooy/"  >{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'Header.WhatIsVooy')[0].value}</a>
                        <a href="https://vooyapp.com.mx/faqs/" >{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'Header.FAQ')[0].value}</a>
                        <a className="support-email-a">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'Header.EmailSupport')[0].value}</a>
                        <a href="https://vooyapp.com.mx/"
                            className="links-div-button"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'Header.SignupHere')[0].value} </a>
                        <a href="https://play.google.com/store/apps/details?id=com.vooy.partner" target="_blank"
                            className="links-div-button"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'Header.DownloadAppLink')[0].value} </a>
                        <button className="download-app-button" onClick={() => this.logout()}> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'Header.Logout')[0].value} </button>
                        <input type="text" className="search-box" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'Header.SearchBoxPlaceholder')[0].value} />
                        <i class="fas fa-search search-icon"></i>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(HeaderComponent);