import React from 'react';
import HeaderComponent from '../../common/header/HeaderComponent';
import SubHeaderComponent from '../../common/subHeader/SubHeaderComponent';
import FooterComponent from '../../common/footer/FooterComponent';
import LayoutRouting from '../../../LayoutRouting';

export default class RegistrationRoutingComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <HeaderComponent />
                <SubHeaderComponent />
                <div>
                    <LayoutRouting />
                </div>
                <FooterComponent />
            </React.Fragment>
        )
    }
}
