import React from 'react';
import { Formik, Field, Form } from 'formik';
import FormikInput from '../../common/formik/FormikInput';
import FormikSelect from '../../common/formik/FormikSelect';
import './LoginModule.css';
import * as  commonService from '../../../service/common/CommonService';
import * as  languageService from '../../../service/common/LanguageService';
import loginState, { formValidationSchemaMobile } from './LoginState';
import * as commonMethods from '../../common/common-methods/CommonMethods';
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';
import VooyHeader from '../../../asset/headerimages/VooyHeader.png'
import Geocode from "react-geocode";

let country = null;
let countryCode = null;

class LoginUserWithMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = loginState;
        this.formikRef = React.createRef();
    }

    componentDidMount = () => {
        this.getLanguageList();
        let result = JSON.parse(localStorage.getItem('languageApiLabels'));
        this.setState({ userLanguageApiLabels: result, isPageLoaded: true, });
        this.getActiveCountryList();
        country = null;
        commonMethods.removeAsyncToken();
    }

    // Gets the language list from API
    getLanguageList = () => {
        commonService.getlanguageDDO()
            .then(res => {
                if (res) {
                    let languageCode = localStorage.getItem('languageCode')
                    if (languageCode === RegConstants.LanguageCodeCD.mexican)
                        res.reverse();

                    this.setState({
                        languageList: [
                            ...res]
                    })
                }
            })
    }

    // Retrieves the country list and calls the method to get the current position
    getActiveCountryList = () => {
        //It calls the method which extracts the current country name according to the location
        //Passes the lat long as the parameter to the method
        navigator.geolocation.getCurrentPosition(this.getCountryByLatLng,
            (error) => {

                // new country changes
                this.getActiveCountryCode("");
            })
    }

    // It calls the method which extracts the current country name according to the location
    // and sets the country code in state for the respective country 
    getCountryByLatLng = (position) => {
        Geocode.setApiKey(RegConstants.GoogleApiKey.googleApiKey);
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
                response.results.every((element) => {
                    let address = element?.address_components?.filter(item => {
                        if (item?.types?.includes("country")) {
                            return item;
                        }
                    });
                    if (address?.length) {
                        countryCode = address[0]?.short_name;
                        return false;
                    }
                    else
                        return true
                });
                // new country changes
                countryCode = !countryCode ? 'MX' : countryCode;
                this.getActiveCountryCode(countryCode);
            },
            (error) => {
                // new country changes
                this.getActiveCountryCode("");
            }
        );
    }


    // sets the retrieved active country code
    getActiveCountryCode = (countryCode) => {
        commonService.getActiveCountry(countryCode).then(res => {
            if (res) {

                let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
                this.setFormValidations(masterApiData, res[0]?.label)

                this.setState({ countryList: [...res], countryCode: res[0].label, });
            }
        })
    }

    // it sets form validation schema 
    setFormValidations = (masterApiData, dialCode) => {

        let mobileValidation = commonMethods.getMinMaxMobileLength(masterApiData, dialCode);
        let validationSchema = formValidationSchemaMobile(masterApiData, mobileValidation.minMobileLength, mobileValidation.maxMobileLength);

        this.setState({
            mobileNoMaxLength: mobileValidation.maxMobileLength,
            formValidationSchemaMobile: validationSchema
        });
    }

    // This method is fired when the country code is changed and the dial code is saved in the state 
    onchangeCountryListHandler = (countryCode) => {
        if (countryCode) {
            this.setState({ countryCode: countryCode }, () => { });
        }
    }

    // This method is used to change apps language via api
    onchangeLanguageListHandler = (languageCode) => {
        let language = '';
        localStorage.setItem("languageCode", languageCode);

        if (languageCode === RegConstants.LanguageCodeCD.mexican) {
            language = RegConstants.LanguageListCD.Spanish;
            localStorage.setItem("language", language);
        }
        else if (languageCode === RegConstants.LanguageCodeCD.us) {
            language = RegConstants.LanguageListCD.English;
            localStorage.setItem("language", language);
        }

        languageService.getLanguageLabels(language, RegConstants.LanguageApiUserInterfaceCD.WebAppUser)
            .then(res => {
                if (res) {
                    localStorage.setItem("languageApiLabels", JSON.stringify(res));

                    if (this.formikRef.current) {
                        this.formikRef.current.resetForm();
                        this.setFormValidations(res, this.state.countryCode);
                    }
                }
            })
    }

    // It sends an otp request to the api and gets an otpuId then routes to the verify otp page along with the otpuId
    onLogin = (loginDetails) => {
        if (this.state.countryCode === "") {
            alert(this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.AlertsInterfaceLabels.PleaseSelectCountry)[0].value);
        }
        else {
            let languageCode = localStorage.getItem("languageCode");
            let otpDetail = {};

            otpDetail.languageCode = languageCode;
            otpDetail.linkTableCD = RegConstants.LinkTableCD.user;
            otpDetail.linkTableUId = '';
            otpDetail.secureActionTypeCD = RegConstants.LoginCD.user;
            otpDetail.sendToMobile = true;
            otpDetail.sendToEmail = false;
            otpDetail.email = "";
            otpDetail.mobileNo = this.state.countryCode + loginDetails.mobileNumber;

            commonService.validateLogin(otpDetail.mobileNo, RegConstants.PartnerTypeCD.customer, languageCode)
                .then((res) => {
                    if (res) {
                        otpDetail.otpuId = res.uId;
                        this.props.history.push({
                            pathname: '/verify-otp',
                            otpDetail: otpDetail,
                            dialCode: loginDetails.country,
                            verifyOtpApiLabels: this.state.userLanguageApiLabels
                        });
                    }
                })
        }
    }

    // Routes to the login with email option
    onChangeLoginOptionHandler = () => {
        this.props.history.push({
            pathname: '/login-user-email',
        })
    }

    // It routes to the forgot password page 
    forgotPassword = () => {
        this.props.history.push({
            pathname: '/forgot-password'
        })
    }

    // Handles the event occuring in mobile number field
    onchangeMobileNoHandler = (event) => {
        if (event.length === 10) {
            this.setState({ isMobileNoValid: true });
        }
        else {
            this.setState({ isMobileNoValid: false });
        }
    }

    render() {
        const { loginDetails, formValidationSchemaMobile } = this.state;
        return (
            <div className="body11">
                <Formik
                    initialValues={loginDetails}
                    innerRef={this.formikRef}
                    validationSchema={formValidationSchemaMobile}
                    onSubmit={this.onLogin}
                >
                    {(formik) => (
                        <React.Fragment >
                            <div className="Header_Section">
                                <div className="login-ride-logo-div">
                                    <img className="vooyLogo_Header" src={VooyHeader} alt="VooyRidePartner" />
                                </div>
                            </div>
                            <div className="main_section">
                                <div className="sub-main-section-otp">
                                    <div className="sub-main-form-section">
                                        <h2 className="heading_vooy">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.VooyUserTitle)[0].value}</h2>
                                        <Form noValidate>
                                            <div>
                                                <div className="form-group">
                                                    <label className="label_userdetail"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.MobileNumberLabel)[0].value}*</label>
                                                    <div className='flex-div'>
                                                        <span className="country-dropdown remove-dropdown-icon"> <Field name='country' className="decorated"
                                                            disabled={true} type="select" option={this.state.countryList} value={this.state.countryCode} onChange={this.onchangeCountryListHandler} component={FormikSelect} />
                                                        </span>
                                                        <span className="w_100"><Field maxLength={this.state.mobileNoMaxLength} placeholder="00 0000 0000" name="mobileNumber" id="mobileNumber" onChange={(event) => this.onchangeMobileNoHandler(event)} type="text" onKeyDown={commonMethods.updateNumber} component={FormikInput} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="change-login-option">
                                                    <label onClick={() => this.onChangeLoginOptionHandler()}><span>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.LoginWithEmail)[0].value}</span></label>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className={(formik.isValid && formik.dirty && this.state.countryCode) ? "top_margin login-button-enabled" : "top_margin login-button-div"} >
                                                        <button type="submit">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.LoginButton)[0].value}</button>
                                                    </div>
                                                </div>
                                                <div className="login_link_div">
                                                    <span className="signup-link-span">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.DoNotHaveAccountLabel)[0].value}&nbsp;&nbsp;
                                                        <a href="https://vooyapp.com.mx/" className="sign-up" target="_blank">
                                                            {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.SignUpButton)[0].value}</a>
                                                    </span>
                                                </div>
                                                <div className="forgot_password_div">
                                                    <label onClick={() => this.forgotPassword()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.ForgotPasswordLink)[0].value}</label>
                                                </div>
                                                <div className="language-dropdown country-dropdown">
                                                    <i className="fas fa-globe-americas" ></i>
                                                    <Field name='language' className="decorated" type="select" option={this.state.languageList} onChange={this.onchangeLanguageListHandler} component={FormikSelect} />
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Formik>
            </div>
        )
    }
}

export default LoginUserWithMobile;
