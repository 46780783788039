import * as Yup from 'yup';
import { translateViaApi } from '../../../common/translator';
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';

const taxProfileStateDuringEdit = () => {
    return {
        name: '',
        rfc: '',
        businessName: '',
        useCFDI: 'G03',
        taxResidenceCountry: '',
        numRegldTrib: '',
        email: '',
        telephone: '',
        address: '',
        extNumber: '',
        intNumber: '',
        flat: '',
        building: '',
        colony: '',
        municipality: '',
        state: '',
        town: '',
        zipcode: '',
        paymentMethod: '',
        invoiceSetting: '',
    }
}
const taxProfileState = () => {
    return {
        name: '',
        rfc: '',
        businessName: '',
        useCFDI: 'G03',
        taxResidenceCountry: '',
        numRegldTrib: '',
        email: '',
        telephone: '',
        address: '',
        extNumber: '',
        intNumber: '',
        flat: '',
        building: '',
        colony: '',
        municipality: '',
        state: '',
        town: '',
        zipcode: '',
        paymentMethod: '',
        invoiceSetting: '',
    }
}
const regexRfc = /^[A-Za-z0-9]+$/
const regexForAcceptAllExceptWhitespace = /^[^\s].*/;
const regexMobileNumber = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const taxProfileValidationSchema = (res, minMobileLength, maxMobileLength) => Yup.object().shape({
    name: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.NameIsInvalid')[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === 'Validation.NameIsRequired')[0]?.value),
    rfc: Yup.string()
        .matches(regexRfc, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.RfcIsInvalidError)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.RfcIsRequiredError)[0]?.value)
        .min(12, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.RfcIsInvalidError)[0]?.value)
        .max(13, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.RfcIsInvalidError)[0]?.value),
    businessName: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.CompanyNameIsInvalid)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.CompanyNameIsRequired)[0]?.value),
    useCFDI: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.CFDIIsInvalid)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.CFDIIsRequired)[0]?.value),
    // taxResidenceCountry: Yup.string()
    //     .matches(regexForAcceptAllExceptWhitespace, { message: translateViaApi(RegConstants.ValidationInterfaceLabels.ResidentialTaxIsInvalid), excludeEmptyString: true })
    //     .required(translateViaApi(RegConstants.ValidationInterfaceLabels.ResidentialTaxIsRequired)),
    // numRegldTrib: Yup.string()
    //     .matches(regexForAcceptAllExceptWhitespace, { message: translateViaApi(RegConstants.ValidationInterfaceLabels.TaxIdIsInvalid), excludeEmptyString: true }),
    // .required(translateViaApi(RegConstants.ValidationInterfaceLabels.TaxIdIsRequired)),
    email: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.EmailTaxIsInvalid)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.EmailTaxIsRequired)[0]?.value),
    telephone: Yup.string()
        .matches(regexMobileNumber, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.PhoneNumberIsInvalid)[0]?.value, excludeEmptyString: true })
        .min(minMobileLength, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.PhoneNumberIsInvalid)[0]?.value)
        .max(maxMobileLength, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.PhoneNumberIsInvalid)[0]?.value)
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.PhoneNumberIsRequired)[0]?.value),
    address: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.AddressIsInvalid)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.AddressIsRequired)[0]?.value),
    extNumber: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.OutsideNumberIsInvalid')[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === 'Validation.OutsideNumberIsRequired')[0]?.value),
    intNumber: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.InnerNumberIsInvalid')[0]?.value, excludeEmptyString: true }),
    // .required(translateViaApi('Validation.InnerNumberIsRequired')),
    flat: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.FloorIsInvalid')[0]?.value, excludeEmptyString: true }),
    // .required(translateViaApi('Validation.FloorIsRequired')),
    building: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.EdificieIsInvalid')[0]?.value, excludeEmptyString: true }),
    // .required(translateViaApi('Validation.EdificieIsRequired')),
    colony: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.SuburbIsInvalid')[0]?.value, excludeEmptyString: true })
        .required(translateViaApi('Validation.SuburbIsRequired')),
    municipality: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.TownIsInvalid')[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === 'Validation.TownIsRequired')[0]?.value),
    // country: Yup.string()
    //     .required(translateViaApi('Validation.FloorIsRequired')),
    state: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.StateIsRequired')[0]?.value),
    town: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.CityIsRequired')[0]?.value),
    zipcode: Yup.string()
        .min(5, res?.filter(i => i.key === 'Validation.PostalCodeLengthError')[0]?.value)
        .required(res?.filter(i => i.key === 'Validation.PostalCodeIsRequired')[0]?.value),
    // paymentMethod: Yup.string()
    //     .matches(regexForAcceptAllExceptWhitespace, { message: translateViaApi('Validation.PaymentMethodIsInvalid'), excludeEmptyString: true })
    //     .required(translateViaApi('Validation.PaymentMethodIsRequired')),
    // invoiceSetting: Yup.string()
    //     .matches(regexForAcceptAllExceptWhitespace, { message: translateViaApi('Validation.InvoiceSettingIsInvalid'), excludeEmptyString: true })
    //     .required(translateViaApi('Validation.InvoiceSettingIsRequired')),
});

export const taxProfileValidationSchemaEdit = (res) => Yup.object().shape({
    name: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.NameIsInvalid')[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === 'Validation.NameIsRequired')[0]?.value),
    rfc: Yup.string()
        .matches(regexRfc, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.RfcIsInvalidError)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.RfcIsRequiredError)[0]?.value)
        .min(12, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.RfcIsInvalidError)[0]?.value)
        .max(13, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.RfcIsInvalidError)[0]?.value),
    businessName: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.CompanyNameIsInvalid)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.CompanyNameIsRequired)[0]?.value),
    useCFDI: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.CFDIIsInvalid)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.CFDIIsRequired)[0]?.value),
    // taxResidenceCountry: Yup.string()
    //     .matches(regexForAcceptAllExceptWhitespace, { message: translateViaApi(RegConstants.ValidationInterfaceLabels.ResidentialTaxIsInvalid), excludeEmptyString: true })
    //     .required(translateViaApi(RegConstants.ValidationInterfaceLabels.ResidentialTaxIsRequired)),
    // numRegldTrib: Yup.string()
    //     .matches(regexForAcceptAllExceptWhitespace, { message: translateViaApi(RegConstants.ValidationInterfaceLabels.TaxIdIsInvalid), excludeEmptyString: true }),
    // .required(translateViaApi(RegConstants.ValidationInterfaceLabels.TaxIdIsRequired)),
    email: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.EmailTaxIsInvalid)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.EmailTaxIsRequired)[0]?.value),
    telephone: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.PhoneNumberIsInvalid)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.PhoneNumberIsRequired)[0]?.value),
    address: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.AddressIsInvalid)[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.AddressIsRequired)[0]?.value),
    extNumber: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.OutsideNumberIsInvalid')[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === 'Validation.OutsideNumberIsRequired')[0]?.value),
    intNumber: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.InnerNumberIsInvalid')[0]?.value, excludeEmptyString: true }),
    // .required(translateViaApi('Validation.InnerNumberIsRequired')),
    flat: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.FloorIsInvalid')[0]?.value, excludeEmptyString: true }),
    // .required(translateViaApi('Validation.FloorIsRequired')),
    building: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.EdificieIsInvalid')[0]?.value, excludeEmptyString: true }),
    // .required(translateViaApi('Validation.EdificieIsRequired')),
    colony: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.SuburbIsInvalid')[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === 'Validation.SuburbIsRequired')[0]?.value),
    municipality: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.TownIsInvalid')[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === 'Validation.TownIsRequired')[0]?.value),
    // country: Yup.string()
    //     .required(translateViaApi('Validation.FloorIsRequired')),
    state: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.StateIsRequired')[0]?.value),
    town: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.CityIsRequired')[0]?.value),
    zipcode: Yup.string()
        .min(5, res?.filter(i => i.key === 'Validation.PostalCodeLengthError')[0]?.value)
        .required(res?.filter(i => i.key === 'Validation.PostalCodeIsRequired')[0]?.value),
    // paymentMethod: Yup.string()
    //     .matches(regexForAcceptAllExceptWhitespace, { message: translateViaApi('Validation.PaymentMethodIsInvalid'), excludeEmptyString: true })
    //     .required(translateViaApi('Validation.PaymentMethodIsRequired')),
    // invoiceSetting: Yup.string()
    //     .matches(regexForAcceptAllExceptWhitespace, { message: translateViaApi('Validation.InvoiceSettingIsInvalid'), excludeEmptyString: true })
    //     .required(translateViaApi('Validation.InvoiceSettingIsRequired')),
});

const states = (function states() {
    return {
        taxProfileState: taxProfileState(),
        taxProfileStateDuringEdit: taxProfileStateDuringEdit(),
        taxProfileValidationSchema: taxProfileValidationSchema(),
        taxProfileValidationSchemaEdit: taxProfileValidationSchemaEdit(),
        userLanguageApiLabels: [],
        isPageLoaded: false,
        countryList: [],
        stateList: [],
        cityList: [],
        isCreateInvoice: false,
        previousInvoiceDetail: {},
        isInvoiceDeleted: false,
        mobileNoMaxLength: null
    }
})()
export default states;