import { axios } from "../../../core/axios.interceptor";
import { invoiceAxios } from "../../../core/InvoiceAPIInterceptor";

export async function getAllRides(sortOrder, filterValue, orderStatusCD, dateOfToday, date30DaysBefore) {
    const response = await axios.get(`Ride?Filter.SortOrder=${sortOrder}&Filter.FilterValue=${filterValue}&Filter.OrderStatusCD=${orderStatusCD}
                 &Filter.FromDateMax=${dateOfToday}&Filter.FromDateMin=${date30DaysBefore}`);
    return response.data;
}


export async function saveInvoice(invoicePayload) {
    const response = await axios.post(`UserInvoiceDetail`, invoicePayload);
    return response.data;
}

export async function getInvoice() {
    const response = await axios.get(`UserInvoiceDetail`);
    return response.data;
}

export async function deleteInvoice(uId) {
    const response = await axios.delete(`UserInvoiceDetail/${uId}`);
    return response.data;
}

export async function updateInvoice(invoicePayload) {
    const response = await axios.put(`UserInvoiceDetail`, invoicePayload);
    return response.data;
}

export async function getReceiptDetails(orderNumber) {
    const response = await axios.get(`Order/billing-detail?orderNumber=${orderNumber}`);
    return response.data;
}

export async function downloadInvoice(orderUId) {
    const response = await invoiceAxios.get(`invoice/download/${orderUId}`);
    return response.data;
}

export async function resendInvoice(orderUId) {
    const response = await axios.get(`Order/send-receipt-rideuser?orderUId=${orderUId}`);
    return response.data;
}

export async function generateInvoice(orderUId) {
    const response = await invoiceAxios.post(`invoice/generate/${orderUId}`);
    return response.data;
}