import React, { Component } from 'react';
import './RideDetails.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";
import states from './RideDetailState';
import Currency from '../../../asset/images/currency.png'
import ReceiptPopup from '../../../asset/images/ReceiptPopup.png'
import Modal from 'react-bootstrap/Modal';
import * as  rideService from '../../../service/auth/my-rides/MyRides.Service';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import { withScriptjs } from "react-google-maps";
import Map from './GoogleMap';
import DiamondIcon from '../../../asset/images/DiamondIcon.png'
import * as moment from 'moment'
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';
import * as commonMethods from '../../common/common-methods/CommonMethods';

let receiptDetail = {};
const MapLoader = withScriptjs(Map);

class RideDetails extends Component {
    constructor(props) {
        super(props);
        this.state = states;
    }

    componentDidMount = () => {
        let result = JSON.parse(localStorage.getItem('languageApiLabels'));
        this.setState({ userLanguageApiLabels: result }, () => { })
        this.setState({ isPageLoaded: true });

        if (!this.props.location.rideDetail) {
            this.props.history.goBack();
        } else {
            this.setState({ ride: this.props.location.rideDetail, expandElementId: this.props.location.rideDetail[0].uId });
            let ride = this.props.location.rideDetail[0];
            localStorage.setItem('pickupLat', ride.pickupLat);
            localStorage.setItem('pickupLon', ride.pickupLon);
            localStorage.setItem('dropLat', ride.dropLat);
            localStorage.setItem('dropLon', ride.dropLon);
        }
    }

    // It closes the receipt pop up 
    hideReceipt = () => {
        this.setState({ isShowReceipt: false })
    }

    // It opens a popup in order to display the receipt details
    showReceipt = () => {
        rideService.getReceiptDetails(this.props.location.rideDetail[0].orderNumber).then(res => {
            if (res) {
                receiptDetail = res;
                this.setState({ isShowReceipt: true })
            }
        })
    }

    // It resends the invoice to the particular email
    resendInvoice = (ride) => {
        rideService.resendInvoice(ride.uId).then((res) => {
            if (res) {
                if (res === true)
                    toast.success(this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.InvoiceResentSuccessfully')[0].value);
                else
                    toast.error(this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.InvoiceResentFailed')[0].value);
            }
        })
    }

    // It displays the option for creating tax profile(invoice)
    showTaxProfile = () => {
        this.setState({ isVeiwTaxProfile: true, isVeiwRides: false });
        this.props.history.push({
            pathname: '/my-rides',
            isVeiwTaxProfile: true
        });
    }

    // It displays all the rides by routing to my-rides page
    showMyRides = () => {
        this.setState({ isVeiwRides: true, isVeiwTaxProfile: false });
        this.props.history.push({
            pathname: '/my-rides'
        });
    }

    render() {
        const { expandElementId } = this.state;
        return (
            <div className="main_section">
                <div className="sub-main-section">
                    <div className="form-section-width">
                        <div className="container collapse-container form-container-width">
                            <div className='toggle-buttons-detail-div'>
                                <button className={this.state.isVeiwTaxProfile ? 'tax-button' : 'toggle-buttons'} onClick={() => this.showTaxProfile()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.TaxProfileButton')[0].value}</button>
                                <button className={this.state.isVeiwRides ? 'ride-button' : 'toggle-buttons'} onClick={() => this.showMyRides()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'MyRides.MyRideButton')[0].value}</button>
                            </div>
                            <div className="rides-div">
                                <MapLoader
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA6pyIkJXN3Bq-zxTZvd4sEFyEjj2bnCFI"
                                    loadingElement={<div style={{ height: `100%` }}
                                        ride={this.state.ride}
                                    />}
                                />
                            </div>
                            <div className="rides-div">
                                {
                                    this.state.ride.map((element, index) => {
                                        return (
                                            <Card key={index} className="card rounded card">
                                                <CardHeader
                                                    className={expandElementId === element.uId ? "expanded-card" : "card"}
                                                    data-event={(index = element.uId)}
                                                >
                                                    <div className="collapse d-flex">
                                                        <div className="ride-date-div">
                                                            {/* <p >{element.createdDateTime}</p> */}
                                                            <p >{moment.utc(element.orderDate).local().format('DD/MMM/YYYY - hh:mm:ss A')}</p>
                                                            <p ><i className="fas fa-dollar-sign doller-icon-ride "></i>{element.totalAmount}</p>
                                                        </div>
                                                        <div className="ride-detail-address">
                                                            <p >{element.pickupLocation}</p>
                                                            <p ><img src={Currency} className="currency-icon" alt="currency"></img>{element.paymentMode}</p>
                                                        </div>
                                                    </div>
                                                    <div className="your-ride-with-div">
                                                        <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.YourRideWith')[0].value}{element.senderName}</p>
                                                    </div>
                                                    <CardBody className="card-body pickup-drop-card">
                                                        <div>
                                                            <div className="pickup-div">
                                                                <i className="fas fa-square-full d-flex start-location-icon"></i>
                                                                <div>
                                                                    <p className="card-text">{element.pickupLocation}</p>
                                                                    {/* <p className="card-text">{element.orderDate}</p> */}
                                                                    <p>{moment.utc(element.orderStatuses.filter(i => i.orderStatusCD === RegConstants.RideOrderStatusCD.Pickup)[0].createdDateTime).local().format('hh:mm:ss A')}</p>
                                                                </div>
                                                            </div>
                                                            <div className="vertical-line"> </div>
                                                            <div className="drop-location-div">
                                                                <img src={DiamondIcon} alt="Diamond Icon"></img>
                                                                <div >
                                                                    <p className="card-text">{element.dropLocation}</p>
                                                                    <p>{moment.utc(element.orderStatuses.filter(i => i.orderStatusCD === RegConstants.RideOrderStatusCD.Drop)[0].createdDateTime).local().format('hh:mm:ss A')}</p>
                                                                    {/* <p className="card-text">{element.orderDate}</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="rating-detail-div">
                                                            <StarRatings
                                                                rating={element.senderRatingValue}
                                                                starRatedColor="#FFD700"
                                                                numberOfStars={5}
                                                                name='rating'
                                                                starDimension="30px"
                                                                starSpacing="2px"
                                                            />
                                                        </div>
                                                    </CardBody>
                                                </CardHeader>
                                                <Collapse isOpen={expandElementId === index}>
                                                    <CardHeader className="expanded-card margin-block-end rating-download-div"  >
                                                        <CardBody className="card-body rating-invoice-div">
                                                            <div>
                                                                <button onClick={() => this.resendInvoice(element)}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.ResendReceipt')[0].value}</button>
                                                                <i class="fas fa-arrow-up arrow-icon"></i>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => this.showReceipt()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.SeeReceipt')[0].value}</button>
                                                                <i class="fas fa-arrow-right arrow-icon"></i>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => commonMethods.downloadInvoicePDF(element)}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.ObtainInvoice')[0].value}</button>
                                                                <i class="fas fa-arrow-down arrow-icon"></i>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => commonMethods.downloadInvoiceXML(element)}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.ObtainInvoiceXML')[0].value}</button>
                                                                <i class="fas fa-arrow-down arrow-icon"></i>
                                                            </div>
                                                        </CardBody>
                                                    </CardHeader>
                                                </Collapse>
                                            </Card>
                                        );
                                    })
                                }
                            </div>
                            <div onClick={() => this.hideReceipt()}>
                                <Modal show={this.state.isShowReceipt} onClick={e => {
                                    // do not close modal if anything inside modal content is clicked
                                    e.stopPropagation();
                                }}>
                                    <Modal.Header >
                                        <i class="fas fa-arrow-left close-icon" onClick={this.hideReceipt}></i>
                                        <label>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.ReceiptHeader')[0].value}</label>
                                    </Modal.Header>
                                    <label className="date-label">{moment(receiptDetail.orderDate).format('DD/MMM/YYYY')}</label>
                                    <label className="see-receipt-label">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.SeeReceiptDetail')[0].value}</label>
                                    <hr className="horizontal-line" />
                                    <img className="receipt-popup-img" src={ReceiptPopup} alt="Receipt"/>
                                    <Modal.Body>
                                        <div className='cost-div'>
                                            <div className="total-amount-div">
                                                <label>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.Total')[0].value}</label>
                                                <label>{receiptDetail.currencyCode === "INR" ?
                                                    <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.INR')[0].value}<i class="fas fa-rupee-sign"></i></p>
                                                    : <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.MX')[0].value}<i class="fas fa-dollar-sign"></i></p>}
                                                    {receiptDetail.totalAmount}</label>
                                            </div>
                                            <div className='amount-div'>
                                                <label>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.TravelFees')[0].value}</label>
                                                <label>{receiptDetail.currencyCode === "INR" ?
                                                    <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.INR')[0].value}<i class="fas fa-rupee-sign"></i></p>
                                                    : <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.MX')[0].value}<i class="fas fa-dollar-sign"></i></p>}
                                                    {receiptDetail.totalAmount}</label>
                                            </div>
                                            <div className='amount-div'>
                                                <label>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.Subtotal')[0].value}</label>
                                                <label>{receiptDetail.currencyCode === "INR" ?
                                                    <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.INR')[0].value}<i class="fas fa-rupee-sign"></i></p>
                                                    : <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.MX')[0].value}<i class="fas fa-dollar-sign"></i></p>}
                                                    {receiptDetail.orderAmount}</label>
                                            </div>
                                            <div className='amount-div'>
                                                <label>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.Tax')[0].value}</label>
                                                <label>{receiptDetail.currencyCode === "INR" ?
                                                    <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.INR')[0].value}<i class="fas fa-rupee-sign"></i></p>
                                                    : <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.MX')[0].value}<i class="fas fa-dollar-sign"></i></p>}
                                                    {receiptDetail.taxAmount}</label>
                                            </div>
                                            <div className='amount-div'>
                                                <label>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.ApplicationCosts')[0].value}</label>
                                                <label>{receiptDetail.currencyCode === "INR" ?
                                                    <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.INR')[0].value}<i class="fas fa-rupee-sign"></i></p>
                                                    : <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.MX')[0].value}<i class="fas fa-dollar-sign"></i></p>}
                                                    {0}</label>
                                            </div>
                                            <div className='amount-div'>
                                                <label>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.AmountPaidInCash')[0].value}</label>
                                                <label>{receiptDetail.currencyCode === "INR" ?
                                                    <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.INR')[0].value}<i class="fas fa-rupee-sign"></i></p>
                                                    : <p>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'RideDetail.MX')[0].value}<i class="fas fa-dollar-sign"></i></p>}
                                                    {receiptDetail.cashToBePaid === 0 ? receiptDetail.orderAmount : receiptDetail.cashToBePaid}</label>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        )
    }
}

export default RideDetails;