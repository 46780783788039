import React from 'react';
import FormikInput from '../../common/formik/FormikInput';
import './LoginModule.css';
import FormikSelect from '../../common/formik/FormikSelect';
import { Formik, Field, Form } from 'formik';
import { toast } from 'react-toastify';
import * as commonMethods from '../../common/common-methods/CommonMethods';
import loginState, { formValidationSchemaEmail } from './LoginState';
import * as loginService from '../../../service/auth/login/Login.Service';
import * as  commonService from '../../../service/common/CommonService';
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';
import * as  languageService from '../../../service/common/LanguageService';
import jwt from 'jwt-decode';
import VooyHeader from '../../../asset/headerimages/VooyHeader.png'

class LoginUserWithEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = loginState;
        this.formikRef = React.createRef();
    }

    componentDidMount = () => {
        this.getLanguageList();
        let result = JSON.parse(localStorage.getItem('languageApiLabels'));
        this.setFormValidationSchema(result);
        commonMethods.removeAsyncToken();
    }

    // it sets the form's validation schema
    setFormValidationSchema = (result) => {
        let validationSchema = formValidationSchemaEmail(result);

        this.setState({
            userLanguageApiLabels: result, isPageLoaded: true,
            formValidationSchemaEmail: validationSchema
        });
    }

    // Gets the language list from API
    getLanguageList = () => {
        commonService.getlanguageDDO()
            .then(res => {
                if (res) {
                    let languageCode = localStorage.getItem('languageCode')
                    if (languageCode === RegConstants.LanguageCodeCD.mexican)
                        res.reverse();

                    this.setState({
                        languageList: [
                            ...res]
                    })
                }
            })
    }

    // Routes to the login with mobile option
    onChangeLoginOptionHandler = () => {
        this.props.history.push({
            pathname: '/login-user-mobile',
        })
    }

    // It checks the profile status, if the profile is approved then it routes to the my-rides page
    onLogin = (loginDetails) => {
        let languageCode = localStorage.getItem("languageCode");
        let formData = new FormData();

        formData.append('client_secret', RegConstants.FormDataEmailCD.client_secret);
        formData.append('grant_type', RegConstants.FormDataEmailCD.grant_type);
        formData.append('client_id', RegConstants.FormDataEmailCD.client_id);
        formData.append('username', loginDetails.userName);
        formData.append('password', loginDetails.userPwd);
        formData.append('languagecode', languageCode);

        loginService.login(formData)
            .then(res => {
                if (res) {
                    // let currentTime = new Date().getTime() / 1000 + res.expires_in;
                    // localStorage.setItem('expires_in', currentTime);

                    // setTimeout(() => {
                    //     commonMethods.getNewToken();
                    // }, (res.expires_in - 30) * 1000);

                    localStorage.setItem('token', res.access_token);
                    localStorage.setItem('refresh_token', res.refresh_token);

                    loginService.checkUserProfileStatus()
                        .then(response => {
                            if (response.userStatusCD === RegConstants.UserStatusCD.Blocked) {
                                localStorage.removeItem('token');
                                toast.error(this.state.userLanguageApiLabels.filter(i => i.key === "Validation.ProfileIsBlocked")[0].value);
                            }
                            else if (response.userStatusCD === RegConstants.UserStatusCD.Deleted) {
                                localStorage.removeItem('token');
                                toast.error(this.state.userLanguageApiLabels.filter(i => i.key === "Validation.ProfileIsDeleted")[0].value);
                            }
                            else if (response.userStatusCD === RegConstants.UserStatusCD.Approved) {
                                let role = jwt(res.access_token).role;
                                if (role === RegConstants.Roles.customer) {
                                    this.props.history.push({
                                        pathname: '/my-rides'
                                    });
                                }
                                else {
                                    toast.error(this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ValidationInterfaceLabels.UserDoesNotExist)[0].value);
                                }
                            }
                        })
                }
            })
    }

    // It routes to the forgot password page 
    forgotPassword = () => {
        this.props.history.push({
            pathname: '/forgot-password',
        })
    }

    // This method is used to change apps language via api
    onchangeLanguageListHandler = (languageCode) => {
        let language = '';
        localStorage.setItem("languageCode", languageCode);

        if (languageCode === RegConstants.LanguageCodeCD.mexican) {
            language = RegConstants.LanguageListCD.Spanish;
            localStorage.setItem("language", language);
        }
        else if (languageCode === RegConstants.LanguageCodeCD.us) {
            language = RegConstants.LanguageListCD.English;
            localStorage.setItem("language", language);
        }

        languageService.getLanguageLabels(language, RegConstants.LanguageApiUserInterfaceCD.WebAppUser)
            .then(res => {
                if (res) {
                    localStorage.setItem("languageApiLabels", JSON.stringify(res));

                    if (this.formikRef.current) {
                        this.formikRef.current.resetForm();

                        this.setFormValidationSchema(res);
                    }
                }
            })
    }

    // It checks the password 
    onchangePasswordHandler = (password) => {
        if (password.length >= 6)
            this.setState({ isPasswordValid: true });
        else
            this.setState({ isPasswordValid: false });
    }

    // It checks the email
    onchangeEmailHandler = (email) => {
        if (email.length > 0)
            this.setState({ isEmailValid: true });
        else
            this.setState({ isEmailValid: false });
    }

    render() {
        const { loginDetails, formValidationSchemaEmail } = this.state;
        return (
            <div className="body11">
                <Formik
                    initialValues={loginDetails}
                    validationSchema={formValidationSchemaEmail}
                    innerRef={this.formikRef}
                    onSubmit={this.onLogin}
                >
                    {() => (
                        <React.Fragment >
                            <div className="Header_Section">
                                <div className="login-ride-logo-div">
                                    <img className="vooyLogo_Header" src={VooyHeader} alt="VooyRidePartner" />
                                </div>
                            </div>
                            <div className="main_section">
                                <div className="sub-main-section-otp">
                                    <div className="sub-main-form-section">
                                        <h2 className="heading_vooy">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.VooyUserTitle)[0].value}</h2>
                                        <Form noValidate>
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="userName">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.EmailLabel)[0].value}</label>
                                                    <Field name="userName" id="userName" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.EmailPlaceHolder)[0].value}
                                                        type="input" onChange={(event) => this.onchangeEmailHandler(event)} component={FormikInput} />
                                                </div>
                                                <div className="form-group">
                                                    <div className="password_div">
                                                        <label>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.PasswordLabel)[0].value}</label>
                                                        <Field name="userPwd" id="userPwd" type="password" placeholder="***********" onChange={(event) => this.onchangePasswordHandler(event)} component={FormikInput} />
                                                        <i className="fa fa-eye" onClick={commonMethods.showHidePassword}></i>
                                                    </div>
                                                </div>
                                                <div className="change-login-option">
                                                    <label onClick={() => this.onChangeLoginOptionHandler()}><span >{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.LoginWithMobileLink)[0].value}</span></label>
                                                </div>
                                                <div className={(this.state.isEmailValid && this.state.isPasswordValid) ? "top_margin login-button-enabled" : "top_margin login-button-div"} >
                                                    <button type="submit" >{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.LoginButton)[0].value}</button>
                                                </div>
                                                <div className="login_link_div">
                                                    <span className="signup-link-span">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.DoNotHaveAccountLabel)[0].value} &nbsp;&nbsp;
                                                        <a href="https://vooyapp.com.mx/" className="sign-up" target="_blank">
                                                            {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.SignUpButton)[0].value}</a></span>
                                                </div>
                                                <div className="forgot_password_div">
                                                    <label onClick={() => this.forgotPassword()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.DocLoginInterfaceLabels.ForgotPasswordLink)[0].value}</label>
                                                </div>
                                                <div className="language-dropdown country-dropdown">
                                                    <i className="fas fa-globe-americas" ></i>
                                                    <Field name='language' className="decorated" type="select" option={this.state.languageList} onChange={this.onchangeLanguageListHandler} component={FormikSelect} />
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Formik>
            </div>
        )
    }
}

export default LoginUserWithEmail;
