import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './FooterModule.css';
import googlePlayStore from '../../../asset/app-store-images/getitongoogleplay.png';
import appleAppStore from '../../../asset/app-store-images/appleappstore.png';
import googlePlayStoreSpanish from '../../../asset/app-store-images/getitonGooglePlaySpanish.png';
import appleAppStoreSpanish from '../../../asset/app-store-images/appleAppStoreSpanish.png';
import Modal from 'react-bootstrap/Modal';
import RideTheCity from '../../../asset/subheaderimages/RideTheCity.png'
import { RegConstants } from "../registration-constants/RegistrationConstants";

let termconditionurl = "";
let policyurl = "";

export default function FooterComponent(props) {
    const [state, setState] = useState({ userLanguageApiLabels: [], isPageLoaded: false });

    useEffect(() => {
        let result = JSON.parse(localStorage.getItem('languageApiLabels'));
        setState(() => ({
            userLanguageApiLabels: result,
            isPageLoaded: true
        }))
    }, []);

    const [isShow, setIsShow] = useState(false);
    const handleClose = () => {
        setIsShow(false);
    }

    const handleOpen = () => {
        setIsShow(true);
    }

    // T&C and policy url
    termconditionurl = localStorage.getItem('languageCode') === RegConstants.LanguageCodeCD.us ? "https://vooyapp.com.mx/terms-and-conditions/" : "https://vooyapp.com.mx/terminos-y-condiciones/";
    policyurl = localStorage.getItem('languageCode') === RegConstants.LanguageCodeCD.us ? "https://vooyapp.com.mx/privacy-policies/" : "https://vooyapp.com.mx/politicas-de-privacidad/";

    return (
        <React.Fragment>
            <div className="footer_section_logo_part">
                <img className="footer_company_logo_vooy" src={RideTheCity} alt="Ride the city" />
            </div>
            <div className="footer_section_social_logo_part">
                <div className="app_store_logo_div">
                    <a href="https://play.google.com/store/apps/details?id=com.vooy.partner" target="_blank" >
                        <img src={localStorage.getItem('languageCode') === RegConstants.LanguageCodeCD.us ?
                            googlePlayStore : googlePlayStoreSpanish} alt="Logo" />
                    </a>
                    <a target="_blank" >
                        <img src={localStorage.getItem('languageCode') === RegConstants.LanguageCodeCD.us ?
                            appleAppStore : appleAppStoreSpanish} className="apple_app_store_img" onClick={() => handleOpen()} alt="Logo" />
                    </a>
                </div>
                <div className="d-block link_section">
                    <a href={policyurl}
                        target="_blank" >{state.isPageLoaded && state.userLanguageApiLabels.filter(i => i.key === 'Footer.PrivacyPolicy')[0].value}</a>
                    <div className="vl"></div>
                    <a href={termconditionurl}
                        className="link_left_items" target="_blank">{state.isPageLoaded && state.userLanguageApiLabels.filter(i => i.key === 'Footer.TermsOfUse')[0].value}</a>
                </div>
                <div className="last_para">{state.isPageLoaded && state.userLanguageApiLabels.filter(i => i.key === 'Footer.VooyCopyright')[0].value}</div>
            </div>
            <Modal className="modal_ios" show={isShow} onHide={() => handleClose()}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body><label className="modal_label">{state.isPageLoaded && state.userLanguageApiLabels.filter(i => i.key === 'Footer.ComingSoonPopup')[0].value}</label></Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
