import { axios } from "../../core/axios.interceptor";
import { tokenAxios } from "../../core/TokenAPIInterceptor";
import { otpAxios } from "../../core/OtpAPIInterceptor";
import { masterAxios } from "../../core/MasterAPIInterceptor";

export async function getCountryDDO() {
    return await axios.get(`Country/getDDO`)
        .then((response) => {
            response.data.map((item) => {
                item.value = item.uId;
                item.label = item.description
            })
            return response.data
        })
}

export function getCountryCode() {
    return axios.get(`Country/getDDO`)
        .then((response) => {
            response.data.map((item) => {
                item.value = item.code;
                item.label = item.code
            })
            return response.data
        })
}

// export async function getlanguageDDO() {
//     return await axios.get(`ConfigLanguage`)
//         .then((response) => {
//             response.data.map((item) => {
//                 item.value = item.code;
//                 item.label = item.name
//             })
//             return response.data
//         })
// }

// Now it is in master api
export function getlanguageDDO() {
    return masterAxios.get(`config/language`)
        .then((response) => {
            response.data.map((item) => {
                item.value = item.code;
                item.label = item.name
            })
            return response.data;
        })
}

// it fetches active countries from api
export function getActiveCountry(countryCode) {
    return axios.get(`Country/get?countryCode=${countryCode}`)
        .then((response) => {
            if (response) {
                let country = [];
                let activeCountry = {
                    value: response.data.uId,
                    label: response.data.code
                }
                country.push(activeCountry);
                return country
            }
        })
}

export function getCountryDDOByCountryName() {
    return axios.get(`Country/getDDO`)
        .then((response) => {
            response.data.map((item) => {
                item.value = item.uId;
                item.label = item.description
            })
            return response.data
        })

}

// get state ddo
export function getStateDDO(countryUid) {
    return axios.get(`State/getDDO?countryUId=` + countryUid)
        .then((response) => {
            response.data.map((item) => {
                item.value = item.name;
                item.label = item.name;
            })
            return response.data
        })

}

// get city ddo
export function getCityDDO(stateUid) {
    return axios.get(`City/getDDO?stateUId=` + stateUid)
        .then((response) => {
            response.data.map((item) => {
                item.value = item.name;
                item.label = item.name;
            })
            return response.data
        })

}

export function getConfigByGroupId(groupId) {
    return axios.get(`ConfigCD/` + groupId)
        .then((response) => {
            if (response.data) {
                response.data.forEach(item => {
                    item.label = item.cdValue;
                    item.value = item.uId;
                });
            }
            return response.data
        })
}

export async function sendOTP(otpDetails) {
    return await otpAxios.post('secureotp', otpDetails)
        .then(response => {
            if (response)
                return response.data
        })
}

export async function checkPartnerExistence(formData) {
    return await tokenAxios.post('user/validate-partner', formData)
        .then(response => {
            if (response)
                return response.data;
        })
}

export async function checkUserExistence(mobileNo, email) {
    if (email === '' && mobileNo !== '') {
        return await tokenAxios.get(`user/profile?mobileNo=${mobileNo}`)
            .then(response => {
                if (response)
                    return response.data;
            })
    }
    // if (mobileNo === '' && email !== '') {
    //     return await tokenAxios.get(`user/profile?email=${email}`)
    //         .then(response => {
    //             if (response)
    //                 return response.data;
    //         })
    // }
    if (mobileNo !== '' && email !== '') {
        return await tokenAxios.get(`user/profile?email=${email}&mobileNo=${mobileNo}`)
            .then(response => {
                if (response)
                    return response.data;
            })
    }
}

export async function updateLangauge() {
    return await axios.put('User/update-language', null)
        .then(response => {
            if (response)
                return response.data;
        })
}

export async function validateLogin(mobileNo, role, languageCode) {
    return await tokenAxios.get(`user/validate-login/${mobileNo}/${role}/${languageCode}`)
        .then(response => {
            if (response)
                return response.data;
        })
}
