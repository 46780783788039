import * as Yup from 'yup';
import { translateViaApi } from '../../../common/translator';
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';

const loginDetails = () => {
    return {
        userName: '',
        userPwd: '',
        languageCode: '',
        mobileNumber: ''
    }
}

// Regular expressions for validating text fields
const regexEmail = /^(?!\.)(?!.*\.$)(?!.*?\.\.)(?!.*?\.@)([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
const regexMobileNumber = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// Login with mobile form validation schema
export const formValidationSchemaMobile = (res, minMobileLength, maxMobileLength) => Yup.object().shape({
    mobileNumber: Yup.string()
        .matches(regexMobileNumber, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginMobileNumberInvalid)[0]?.value, excludeEmptyString: false })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginMobileNumberIsRequired)[0]?.value)
        .min(minMobileLength, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginMobileNumberInvalid)[0]?.value)
        .max(maxMobileLength, res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginMobileNumberInvalid)[0]?.value),
});

// Login with email form validation schema
export const formValidationSchemaEmail = (res) => Yup.object().shape({
    userName: Yup.string()
        .matches(regexEmail, { message: res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginEmailIsInvalid)[0]?.value, excludeEmptyString: false })
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginEmailIsRequired)[0]?.value),
    userPwd: Yup.string()
        .required(res?.filter(i => i.key === RegConstants.ValidationInterfaceLabels.LoginPasswordIsRequired)[0]?.value),
});

// States
const loginState = (function states(props) {
    return {
        loginDetails: loginDetails(),
        formValidationSchemaMobile: formValidationSchemaMobile,
        formValidationSchemaEmail: formValidationSchemaEmail,
        languageCodelist: [],
        countryList: [],
        loginOption: false,
        mobileNumber: '',
        countryCode: '',
        userLanguageApiLabels: [],
        isPageLoaded: false,
        language: '',
        languageList: [],
        isMobileNoValid: false,
        isPasswordValid: false,
        isEmailValid: false,
        mobileNoMaxLength: null
    }
})()

export default loginState;