import React from 'react';
import './ForgotPassword.css';
import { Formik, Field, Form } from 'formik';
import FormikInput from '../../../component/common/formik/FormikInput';
import ForgotPasswordState, { formValidationSchemaEmail } from './ForgotPasswordState';
import * as loginService from '../../../service/auth/login/Login.Service';
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';
import VooyHeader from '../../../asset/headerimages/VooyHeader.png'

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = ForgotPasswordState
    }

    componentDidMount() {
        let result = JSON.parse(localStorage.getItem('languageApiLabels'));
        let validationSchema = formValidationSchemaEmail(result);
    
        this.setState({
            userLanguageApiLabels: result, isPageLoaded: true,
            formValidationSchemaEmail: validationSchema,
        });
    }

    // It sends the otp and retrieves the otpuId then redirects to reset password page 
    sendOTP = (userDetails) => {
        let formData = new FormData();
        let languageCode = localStorage.getItem('languageCode');

        formData.append('languageCode', languageCode);
        formData.append('username', userDetails.userName);
        formData.append('secureActionTypeCD', RegConstants.SecureActionTypeCD.forgotPassword);
        formData.append('linkTableCD', RegConstants.LinkTableCD.user);

        loginService.forgotPassword(formData).then(res => {
            if (res.data) {
                this.props.history.push({
                    pathname: '/reset-password',
                    otpuId: res.data.uId,
                    formData: formData
                })
            }
        })
    }

    // Routes to the login page
    onLogin = () => {
        this.props.history.push({
            pathname: '/login-user-mobile',
        });
    }

    // It checks the email
    onchangeEmailHandler = (email) => {
        if (email.length > 0)
            this.setState({ isEmailValid: true });
        else
            this.setState({ isEmailValid: false });
    }

    render() {
        const { forgotPasswordDetails, formValidationSchemaEmail } = this.state;
        return (
            <div className="body11">
                <Formik
                    initialValues={forgotPasswordDetails}
                    validationSchema={formValidationSchemaEmail}
                    onSubmit={this.sendOTP}
                >
                    {() => (
                        <React.Fragment >
                            <div className="Header_Section">
                                <div className="login-ride-logo-div">
                                    <img className="vooyLogo_Header" src={VooyHeader} alt="VooyRidePsrtner" />
                                </div>
                            </div>
                            <div className="main_section">
                                <div className="sub-main-section-otp">
                                    <div className="sub_main_form_section">
                                        <h2 className="heading_vooy">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'ForgotPassword.ForgotPasswordHeader')[0].value}</h2>
                                        <Form noValidate>
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="userName">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ForgotPasswordInterfaceLabels.EmailLabel)[0].value}</label>
                                                    <Field name="userName" id="userName" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ForgotPasswordInterfaceLabels.EmailPlaceholder)[0].value}
                                                        type="input" onChange={(event) => this.onchangeEmailHandler(event)} component={FormikInput} />
                                                </div>
                                                <div className="login-btn row" >
                                                    <div className={this.state.isEmailValid ? "col-sm-12 forget-button-div-email-enabled" : "col-sm-12 forget-button-div-email"}>
                                                        <button type="submit">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ForgotPasswordInterfaceLabels.SendOtpButton)[0].value}</button>
                                                    </div>
                                                    <div className="col-sm-12 text-center ">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ForgotPasswordInterfaceLabels.AlreadyHaveAccount)[0].value}<span className="change-login-option">
                                                        &nbsp;&nbsp;<label onClick={this.onLogin}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ForgotPasswordInterfaceLabels.LoginLinkLabel)[0].value} </label></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Formik>
            </div>
        )
    }
}

export default ForgotPassword;
