import React from 'react';
import otpStates from './VerifyOTPState';
import './VerifyOTP.css';
import { toast } from 'react-toastify';
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';
import * as commonMethods from '../../common/common-methods/CommonMethods';
import * as  commonService from '../../../service/common/CommonService';
import * as loginService from '../../../service/auth/login/Login.Service';
import jwt from 'jwt-decode';

let mobileNo = '';

class VerifyOTPCommponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = otpStates;
    }

    componentDidMount() {
        if (!this.props.location.verifyOtpApiLabels) {
            this.setState({ isPageLoaded: false },
                () => { });
            this.props.history.goBack();
        }
        else {
            this.setState({ verifyOtpApiLabels: this.props.location.verifyOtpApiLabels });
            this.setState({ isPageLoaded: true });
        }
        if (this.props.location.otpDetail)
            mobileNo = this.props.location.otpDetail.mobileNo;
    }

    // It validates otp and saves the user detail and redirects to upload document page
    // Also if otp is verifying for login , it validates otp then redirects user to other pages according to user profile status
    validate = () => {
        let languageCode = localStorage.getItem("languageCode");
        let formData = new FormData();

        formData.append('grant_type', RegConstants.FormDataMobileCD.grant_type);
        formData.append('client_id', RegConstants.FormDataMobileCD.client_id);
        formData.append('otp_uid', this.props.location.otpDetail.otpuId);
        formData.append('sms_otp', this.state.mobileOtp);
        formData.append('languagecode', languageCode);

        loginService.login(formData)
            .then(res => {
                if (res) {
                    // this.getNewTokenBeforeExpiration(res);

                    localStorage.setItem('token', res.access_token);
                    localStorage.setItem('refresh_token', res.refresh_token);

                    loginService.checkUserProfileStatus()
                        .then(response => {
                            if (response.userStatusCD === RegConstants.UserStatusCD.Blocked) {
                                localStorage.removeItem('token');
                                this.props.history.push({
                                    pathname: '/login-user-mobile',
                                })
                                toast.error(this.state.verifyOtpApiLabels.filter(i => i.key === "Validation.ProfileIsBlocked")[0].value);
                            }
                            else if (response.userStatusCD === RegConstants.UserStatusCD.Deleted) {
                                localStorage.removeItem('token');
                                this.props.history.push({
                                    pathname: '/login-user-mobile',
                                })
                                toast.error(this.state.verifyOtpApiLabels.filter(i => i.key === "Validation.ProfileIsDeleted")[0].value);
                            }
                            else if (response.userStatusCD === RegConstants.UserStatusCD.Approved) {
                                let role = jwt(res.access_token).role;
                                if (role === RegConstants.Roles.customer) {
                                    this.props.history.push({
                                        pathname: '/my-rides'
                                    });
                                }
                                else {
                                    toast.error(this.state.verifyOtpApiLabels.filter(i => i.key === RegConstants.ValidationInterfaceLabels.UserDoesNotExist)[0].value);
                                }
                            }
                        })
                }
            })
    }

    // it fetches a new token when token is about to expire
    // getNewTokenBeforeExpiration = (res) => {

    //     let currentTime = new Date().getTime() / 1000 + res.expires_in;
    //     localStorage.setItem('expires_in', currentTime);

    //     localStorage.setItem('token', res.access_token);
    //     localStorage.setItem('refresh_token', res.refresh_token);

    //     setTimeout(() => {
    //         commonMethods.getNewToken();
    //     }, (res.expires_in - 30) * 1000);
    // }

    // It resends the otp then gets the otpuId and sets in the current otpuId
    resendOTP = () => {
        commonService.sendOTP(this.props.location.otpDetail)
            .then(res => {
                if (res) {
                    if (this.props.location.otpDetail.otpuId) {
                        this.props.location.otpDetail.otpuId = res.uId;
                    }
                    toast.success(this.state.verifyOtpApiLabels.filter(i => i.key === RegConstants.ValidationInterfaceLabels.OtpResendSuccussfully)[0].value);
                }
            })
    }

    // verifies otp
    onVerifyOTP = () => {
        if (this.state.mobileOtp.length === 0 || this.state.mobileOtp.length < 6)
            this.setState({ isMobileOtpError: true });

        else if (this.state.mobileOtp.length === 6) {
            this.setState({ isMobileOtpError: false });
            this.validate();
        }
    }

    // sets the user entered otp in state
    onChangeOtpHandler = (event) => {
        if (event.target.value.length >= 0) {
            this.setState({ mobileOtp: event.target.value })
        }
        if (event.target.value.length < 6)
            this.setState({ isMobileOtpError: true, isMobileOtpCorrect: false });
        if (event.target.value.length === 6)
            this.setState({ isMobileOtpError: false, isMobileOtpCorrect: true });
    }

    render() {
        { this.state.isPageLoaded && document.getElementById("mobileOtp").scrollIntoView(); }
        return (
            <React.Fragment>
                <div className="main_section">
                    <div className="sub-main-section-otp">
                        <div className="sub_main_form_section">
                            <h2 className="verify_otp_heading">{this.state.isPageLoaded && this.state.verifyOtpApiLabels.filter(i => i.key === "VerifyOTP.VerifyOTP")[0].value}</h2>
                            <div>
                                <div className="form-group col-sm-12 top_section">
                                    {this.state.isPageLoaded && this.state.verifyOtpApiLabels.filter(i => i.key === "VerifyOTP.EnterReceivedOtpLabel")[0].value}
                                </div>
                                <div className="form-group col-sm-6 button-verify top_section">
                                    <label htmlFor="userName" className="label-verify">{this.state.isPageLoaded && this.state.verifyOtpApiLabels.filter(i => i.key === "VerifyOTP.PhoneNumberLabel")[0].value}*:{mobileNo === '' ? '' : mobileNo} </label>
                                    <div className="input_fitting">
                                        <input autoFocus className="form-control" name="mobileOtp" id="mobileOtp" type="text" maxLength="6" value={this.state.mobileOtp} onKeyDown={commonMethods.updateNumber} onChange={(event) => this.onChangeOtpHandler(event)} />
                                        <div className="resend_buton_div">
                                            <label className="resend_otp_button" onClick={() => this.resendOTP()}> {this.state.isPageLoaded && this.state.verifyOtpApiLabels.filter(i => i.key === "VerifyOTP.ResendOTP")[0].value}</label>
                                        </div>
                                    </div>
                                    {this.state.isMobileOtpError ? <span className="error otp_error_message">{this.state.isPageLoaded && this.state.verifyOtpApiLabels.filter(i => i.key === "VerifyOTP.OtpRequiredErrorLabel")[0].value}</span> : ''}
                                </div>
                            </div>
                            <div className={this.state.isMobileOtpCorrect ? "top_margin otp_verify_button_div" : "top_margin otp_verify_button_disabled"}>
                                <button onClick={() => this.onVerifyOTP()}>{this.state.isPageLoaded && this.state.verifyOtpApiLabels.filter(i => i.key === "VerifyOTP.SendButton")[0].value}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default VerifyOTPCommponent;