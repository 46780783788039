import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouting from './AppRouting';
import './core/axios.interceptor';
import { RegConstants } from './component/common/registration-constants/RegistrationConstants';
import * as  languageService from './service/common/LanguageService';
import { Component } from 'react';
import { HashRouter as Router } from 'react-router-dom';
// import { getNewToken } from './component/common/common-methods/CommonMethods';
import { createHashHistory } from 'history';
// let isLanguageDataAvailable = false;

class AppContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { isLanguageDataAvailable: false };
    }

    componentDidMount = () => {
        // this.checkIfTokenIsExpired();
        let language = localStorage.getItem('language');
        if (!language) {
            this.onchangeLanguageListHandler('Spanish');
        } else {
            this.onchangeLanguageListHandler(language);
        }
    }

    // It checks if the current time has exceeded the previous token expiry time or not
    // if it token is expired or about to expire then it fetches a new token and refresh token
    // checkIfTokenIsExpired = () => {
    //     let refreshToken = localStorage.getItem('refresh_token');
    //     if (refreshToken) {

    //         let expires_in = localStorage.getItem('expires_in')
    //         let currentTime = new Date().getTime() / 1000;

    //         let allowAccessForNewToken = ((Number(expires_in) - 30) < currentTime ? true : false);

    //         if (allowAccessForNewToken)
    //             getNewToken();
    //         else {
    //             let totalTimeForRefreshInMs = (Number(expires_in) - currentTime - 30) * 1000;

    //             setTimeout(() => {
    //                 getNewToken();
    //             }, (totalTimeForRefreshInMs));
    //         }
    //     }
    // }

    //This method is used to change apps language via api
    onchangeLanguageListHandler = (language) => {
        languageService.getLanguageLabels(language, RegConstants.LanguageApiUserInterfaceCD.WebAppUser)
            .then(res => {
                if (res) {
                    localStorage.setItem("language", language);

                    if (language === RegConstants.LanguageListCD.Spanish)
                        localStorage.setItem("languageCode", RegConstants.LanguageCodeCD.mexican);
                    else if (language === RegConstants.LanguageListCD.English)
                        localStorage.setItem("languageCode", RegConstants.LanguageCodeCD.us);

                    localStorage.setItem("languageApiLabels", JSON.stringify(res));
                    this.setState({ isLanguageDataAvailable: true });

                    let token = localStorage.getItem('token');
                    localStorage.removeItem('isTokenRefreshed');
                    const history = createHashHistory();
                    if (token)
                        history.push({ pathname: '/my-rides' })
                }
            })
    }

    render() {
        return (
            <div>
                <Router>
                    <ToastContainer />
                    {this.state.isLanguageDataAvailable && <AppRouting />}
                </Router>
            </div>
        )
    }
}

export default AppContainer;