import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import './Loader.css';
import './VooySpinner.css';
import { Environment } from './Environment';
// import { createHashHistory } from 'history';
// import * as loginService from '../service/auth/login/Login.Service';
// import { RegConstants } from '../component/common/registration-constants/RegistrationConstants';
import { getNewToken } from '../component/common/common-methods/CommonMethods';

const url = Environment.getVooyMasterServiceUrl();
const axiosInstance = axios.create({
    baseURL: url,
    timeout: 180000,
    headers: {
        'Accept': 'text/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});
let result = JSON.parse(localStorage.getItem('languageApiLabels'));

// const history = createHashHistory();
axiosInstance.defaults.headers.common['Authorization'] = '';

axiosInstance.interceptors.request.use(function (config) {
    document.body.classList.add('loading-indicator');
    const accessToken = localStorage.getItem('token');
    if (accessToken) {
        config.headers.Authorization = accessToken ? `bearer ${accessToken}` : '';
    }
    return config;

}, function (error) {
    // Do something with request error
    return Promise.reject(error);

});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    document.body.classList.remove('loading-indicator');
    return response;
}, async function (error) {
    if (!error.response) {
        document.body.classList.remove('loading-indicator');
        if (result)
            toast.error(result.filter(i => i.key === 'StatusCodeErrors.NoInternetOrBadGateway')[0].value);
        return 'Something went wrong!';
    } else {
        // Internal server error
        if (error.response.status === 500) {
            if (error.response.data) {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message);
                }
                else
                    toast.error(error.response.data);
            }
            else {
                if (result)
                    toast.error(result.filter(i => i.key === 'StatusCodeErrors.SomeThingWentWrong')[0].value);
            }
            document.body.classList.remove('loading-indicator');
            // return error.response.data;
        }
        else if (error.response.status === 405) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.MethodNotAllowed')[0].value);
        } else if (error.response.status === 408) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.RequestTimeout')[0].value);
        } else if (error.response.status === 415) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.UnsupportedMediaType')[0].value);
        } else if (error.response.status === 502) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.BadGateway')[0].value);
        } else if (error.response.status === 503) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.ServiceUnavailable')[0].value);
        } else if (error.response.status === 504) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.GatewayTimeout')[0].value);
        }
        else
            // bad request
            if (error.response.status === 400) {
                if (error.response.data.validationErrors !== undefined ? error.response.data.validationErrors.length !== 0 : false) {
                    toast.error(error.response.data.validationErrors[0].errorMessage);
                    document.body.classList.remove('loading-indicator');
                    // return error.response.data.validationErrors[0].errorMessage;
                } else if (error.response.data.smsSendingError) {
                    toast.error(error.response.data.smsSendingError);
                    document.body.classList.remove('loading-indicator');
                    // return error.response.data.smsSendingError;
                }
            } else
                // Not found
                if (error.response.status === 404) {
                    if (error.response.statusText)
                        toast.error(error.response.statusText);
                    else
                        if (result)
                            toast.error(result.filter(i => i.key === 'StatusCodeErrors.MethodNotFound')[0].value);
                    document.body.classList.remove('loading-indicator');
                    // return error.response.statusText;
                }
                else
                    // unauthorized
                    if (error.response.status === 401) {

                        let isTokenRefreshed = localStorage.getItem('isTokenRefreshed');
                        if (!isTokenRefreshed) {

                            let newToken = null;
                            newToken = await getNewToken();

                            if (newToken) {
                                localStorage.removeItem('isTokenRefreshed')
                                return axiosInstance(error.config);
                            }
                        }
                    }
                    else {
                        toast.error(error.response.statusText);
                        document.body.classList.remove('loading-indicator');
                        // return error.response.statusText;
                    }
        if (error.response.data) {
            toast.error(error.response.data.error_description);
            document.body.classList.remove('loading-indicator');
            // return error.response.data.error_description;
        }
    }

    document.body.classList.remove('loading-indicator');

    return Promise.reject(error);
});

export { axiosInstance as masterAxios };