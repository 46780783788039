// States
const states = (function states() {
    return {
        // verifyOTPState:verifyOTPState(),
        rides: [],
        isVeiwTaxProfile: false,
        userLanguageApiLabels: [],
        isPageLoaded: false,
        isVeiwRides: true,
        expandElementId: 0,
        isShowPreviousRides: true
    }
})()
export default states;