// Environment Configuration object to store details of server urls
// for dev server use : dev
// for test server use : test
// for production server use : pre

const env = 'pre';

const LogisticApiUrl = `https://${env}-api.vooyapp.com/api/`;
const OtpApiUrl = `https://${env}-otp.vooyapp.com/api/v1/`;
const InvoiceApiUrl = `https://${env}-invoice.vooyapp.com/api/v1/`;
const TokenApiUrl = `https://${env}-token.vooyapp.com/`;
const MasterApiUrl = `https://${env}-master.vooyapp.com/api/v1/`;

const Environment = {

  getUrl() {
    return LogisticApiUrl;
  },
  getOtpAPIUrl() {
    return OtpApiUrl;
  },
  getTokenAPIUrl() {
    return TokenApiUrl;
  },
  getInvoiceAPIUrl() {
    return InvoiceApiUrl;
  },
  getVooyMasterServiceUrl() {
    return MasterApiUrl;
  },
};

export { Environment };
