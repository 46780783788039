import React, { Fragment } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import MyRides from './component/auth/my-rides/MyRides';
import RideDetails from './component/auth/ride-details/RideDetails';
import VerifyOTPCommponent from './component/auth/verify-otp/VerifyOTPComponent';

const LayoutRouting = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path='/ride-details' component={RideDetails} />
                <Route exact path='/verify-otp' component={VerifyOTPCommponent} />
                <Route exact path='/my-rides' component={MyRides} />
                <Redirect from='/' to='/login-user-mobile' />
            </Switch>
        </Fragment>
    )
}
export default withRouter(LayoutRouting);
