export const SecureActionTypeCD = () => {
    return {
        doctor: "DOCTOR_REGISTRATION",
        nurse: "NURSE_REGISTRATION",
        ambulance: "AMB_DRIVER_REGISTRATION",
        forgotPassword: "FORGOT_PASSWORD"
    }
}
export const UserStatusCD = () => {
    return {
        Registered: 101,
        ReviewInProcess: 102,
        Approved: 103,
        Rejected: 104,
        Blocked: 105,
        Suspended: 106,
        Deleted: 107,
        DeletedPermanently: 108
    }
}
export const RideFilterValueList = () => {
    return {
        Upcoming: 1,
        Previous: 2,
        Shared: 3,
    }
}

export const RideOrderStatusCD = () => {
    return {
        Created: 301,
        Accepted: 304,
        Pickup: 305,
        OnTheWay: 306,
        Drop: 307,
        Completed: 308,
    }
}

export const ApprovalStatusCD = () => {
    return {
        Approved: "A",
        Created: "C",
        Deleted: "D",
        Rejected: "R",
        Review_in_process: "P",
        PermanentDeleted: "PD"
    }
}

export const LinkTableCD = () => {
    return {
        doctor: "DCT",
        nurse: "NUR",
        ambulance: "AMB",
        user: "CST"
    }
}

export const Country = () => {
    return {
        Mexico: 'Mexico',
        India: "India"
    }
}

export const Roles = () => {
    return {
        seller: 'seller',
        driver: "driver",
        nurse: 'nurse',
        customer: 'customer',
        doctor: 'doctor',
        ambulanceDriver: 'ambulanceDriver'
    }
}

export const LoginCD = () => {
    return {
        doctor: "DOCTOR_LOGIN",
        nurse: "NURSE_LOGIN",
        ambulance: "AMB_DRIVER_LOGIN",
        user: "USER_LOGIN"
    }
}

export const FormDataMobileCD = () => {
    return {
        grant_type: "sms_otp",
        client_id: "customer_ui",
    }
}

export const FormDataEmailCD = () => {
    return {
        grant_type: "password",
        client_id: "customer_ui",
        client_secret: "secret"
    }
}

export const PartnerTypeCD = () => {
    return {
        customer: "customer",
        ambulanceDriver: "ambulanceDriver",
        nurse: "nurse",
        doctor: "doctor",
        user: "customer"
    }
}

export const LanguageCodeCD = () => {
    return {
        mexican: "es-MX",
        us: "en-US"
    }
}

export const LanguageList = () => {
    return (
        [{ value: 'Spanish', label: 'Spanish' }, { value: 'English', label: 'English' },]
    )
}

export const LanguageListEnglish = () => {
    return (
        [{ value: 'English', label: 'English' }, { value: 'Spanish', label: 'Spanish' },]
    )
}

export const CountryListTaxProfile = () => {
    return (
        [{ value: 'MEX', label: 'Mexico' },]
    )
}

export const LanguageApiUserInterfaceCD = () => {
    return {
        DoctorLogin: 'DoctorLogin',
        NurseLogin: 'NurseLogin',
        AmbulanceLogin: 'AmbulanceLogin',
        Health_Registration: 'Health_Registration',
        WebAppUser: 'WEB_User_Ui'
    }
}

export const LanguageListCD = () => {
    return {
        English: 'English',
        Spanish: 'Spanish',
    }
}

export const DocLoginInterfaceLabels = () => {
    return {
        VooyUserTitle: 'Login.VooyUserTitle',
        MobileNumberLabel: 'Login.MobileNumberLabel',
        PleaseSelectLabel: 'Login.PleaseSelectLabel',
        LoginWithEmail: 'Login.LoginWithEmail',
        ForgotPasswordLink: 'Login.ForgotPasswordLink',
        DoNotHaveAccountLabel: 'Login.DoNotHaveAccountLabel',
        SignUpButton: 'Login.SignUpButton',
        EmailLabel: 'Login.EmailLabel',
        EmailPlaceHolder: 'Login.EmailPlaceHolder',
        PasswordLabel: 'Login.PasswordLabel',
        LoginWithMobileLink: 'Login.LoginWithMobileLink',
        LoginButton: 'Login.LoginButton',
    }
}

export const HeaderInterfaceLabels = () => {
    return {
        HomeLink: 'VooyHealthHeader.HomeLink',
        DoctorLink: 'VooyHealthHeader.DoctorLink',
        NurseLink: 'VooyHealthHeader.NurseLink',
        AmbulanceLink: 'VooyHealthHeader.AmbulanceLink',
        DownloadAppLink: 'VooyHealthHeader.DownloadAppLink',
    }
}

export const FooterInterfaceLabels = () => {
    return {
        PrivacyPolicy: 'VooyHealthFooter.PrivacyPolicy',
        TermsOfUse: 'VooyHealthFooter.TermsOfUse',
        VooyCopyright: 'VooyHealthFooter.VooyCopyright',
    }
}

export const ForgotPasswordInterfaceLabels = () => {
    return {
        VooyHealthHeader: 'ForgotPassword.VooyHealthHeader',
        SendOtpButton: 'ForgotPassword.SendOtpButton',
        LoginLinkLabel: 'ForgotPassword.LoginLinkLabel',
        EmailPlaceholder: 'ForgotPassword.EmailPlaceholder',
        EmailLabel: 'ForgotPassword.EmailLabel',
        EmailIsRequiredError: 'ForgotPassword.EmailIsRequiredError',
        EmailIsInvalid: 'ForgotPassword.EmailIsInvalid',
        AlreadyHaveAccount: 'ForgotPassword.AlreadyHaveAccount',
    }
}

export const ResetPasswordInterfaceLabels = () => {
    return {
        VooyHealthHeader: 'ResetPassword.VooyHealthHeader',
        ResendOtpLink: 'ResetPassword.ResendOtpLink',
        NewPasswordLabel: 'ResetPassword.NewPasswordLabel',
        EnterOTPPlaceholder: 'ResetPassword.EnterOTPPlaceholder',
        EnterOTPLabel: 'ResetPassword.EnterOTPLabel',
        ConfirmPasswordLabel: 'ResetPassword.ConfirmPasswordLabel',
        ConfirmPasswordIsRequired: 'ResetPassword.ConfirmPasswordIsRequired',
        ChangePasswordButton: 'ResetPassword.ChangePasswordButton',
        // VooyHealthHeader: 'ResetPassword.VooyHealthHeader',
        SendOtpButton: 'ResetPassword.SendOtpButton',
        LoginLinkLabel: 'ResetPassword.LoginLinkLabel',
        EmailPlaceholder: 'ResetPassword.EmailPlaceholder',
        EmailLabel: 'ResetPassword.EmailLabel',
        EmailIsRequiredError: 'ResetPassword.EmailIsRequiredError',

        EmailIsInvalid: 'ResetPassword.EmailIsInvalid',
        AlreadyHaveAccount: 'ResetPassword.AlreadyHaveAccount',
    }
}

export const AlertsInterfaceLabels = () => {
    return {
        PleaseSelectCountry: 'Alert.PleaseSelectCountry',
    }
}

export const TaxProfileInterfaceLabels = () => {
    return {
        RFC: 'TaxProfile.RFC',
        RfcPlaceholder: 'TaxProfile.RfcPlaceholder',
        CompanyNamePlaceHolder: 'TaxProfile.CompanyNamePlaceHolder',
        CompanyName: 'TaxProfile.CompanyName',
        CFDI: 'TaxProfile.CFDI',
        CFDIPlaceholder: 'TaxProfile.CFDIPlaceholder',
        ResidentialTax: 'TaxProfile.ResidentialTax',
        ResidentialTaxPlcaholder: 'TaxProfile.ResidentialTaxPlcaholder',
        TaxIdNumber: 'TaxProfile.TaxIdNumber',
        TaxIdNumberPlaceholder: 'TaxProfile.TaxIdNumberPlaceholder',
        EmailLabel: 'TaxProfile.EmailLabel',
        EmailLabelPlaceHolder: 'TaxProfile.EmailLabelPlaceHolder',
        PhoneNumber: 'TaxProfile.PhoneNumber',
        PhoneNumberPlaceholder: 'TaxProfile.PhoneNumberPlaceholder',
        Address: 'TaxProfile.Address',
        AddressPlaceholder: 'TaxProfile.AddressPlaceholder',
        OutsideNumber: 'TaxProfile.OutsideNumber',
        OutsideNumberPlaceholder: 'TaxProfile.OutsideNumberPlaceholder',

        // EmailLabelPlaceHolder: 'TaxProfile.EmailLabelPlaceHolder',
        // EmailLabel: 'TaxProfile.EmailLabel',
        // EmailLabelPlaceHolder: 'TaxProfile.EmailLabelPlaceHolder',
        // PhoneNumber: 'TaxProfile.PhoneNumber',
        // PhoneNumberPlaceholder: 'TaxProfile.PhoneNumberPlaceholder',
        // Address: 'TaxProfile.Address',
        // AddressPlaceholder: 'TaxProfile.AddressPlaceholder',
        // OutsideNumber: 'TaxProfile.OutsideNumber',
        // OutsideNumberPlaceholder: 'TaxProfile.OutsideNumberPlaceholder',
    }
}

export const ValidationInterfaceLabels = () => {
    return {
        EnterValidInput: 'Validation.EnterValidInput',
        MaxFileSizeAllowed: 'Validation.MaxFileSizeAllowed',
        AddressIsRequired: 'Validation.AddressIsRequired',
        LoginMobileNumberIsRequired: 'Validation.LoginMobileNumberIsRequired',
        LoginMobileNumberInvalid: 'Validation.LoginMobileNumberInvalid',
        UserDoesNotExist: 'Validation.UserDoesNotExist',
        LoginEmailIsRequired: 'Validation.LoginEmailIsRequired',
        LoginPasswordIsRequired: 'Validation.LoginPasswordIsRequired',
        LoginEmailIsInvalid: 'Validation.LoginEmailIsInvalid',
        YouHaveLoggedInSuccessfully: 'Validation.YouHaveLoggedInSuccessfully',
        ProfileIsDeleted: 'Validation.ProfileIsDeleted',
        ProfileIsUnderReview: 'Validation.ProfileIsUnderReview',
        ProfileIsRejected: 'Validation.ProfileIsRejected',
        OtpIsRequired: 'Validation.OtpIsRequired',
        OtpIsInvalid: 'Validation.OtpIsInvalid',
        PasswordIsChanged: 'Validation.PasswordIsChanged',
        OtpResendSuccussfully: 'Validation.OtpResendSuccussfully',
        BillingFieldsAreRequired: 'Validation.BillingFieldsAreRequired',
        RfcIsRequiredError: 'Validation.RfcIsRequiredError',
        RfcIsInvalidError: 'Validation.RfcIsInvalidError',
        CompanyNameIsRequired: 'Validation.CompanyNameIsRequired',
        CompanyNameIsInvalid: 'Validation.CompanyNameIsInvalid',
        CFDIIsInvalid: 'Validation.CFDIIsInvalid',
        CFDIIsRequired: 'Validation.CFDIIsRequired',
        ResidentialTaxIsInvalid: 'Validation.ResidentialTaxIsInvalid',
        ResidentialTaxIsRequired: 'Validation.ResidentialTaxIsRequired',
        TaxIdIsInvalid: 'Validation.TaxIdIsInvalid',
        TaxIdIsRequired: 'Validation.TaxIdIsRequired',
        EmailTaxIsInvalid: 'Validation.EmailTaxIsInvalid',
        EmailTaxIsRequired: 'Validation.EmailTaxIsRequired',
        PhoneNumberIsInvalid: 'Validation.PhoneNumberIsInvalid',
        PhoneNumberIsRequired: 'Validation.PhoneNumberIsRequired',
        AddressIsInvalid: 'Validation.AddressIsInvalid',
        // AddressIsRequired: 'Validation.AddressIsRequired',
        OutsideNumberIsInvalid: 'Validation.OutsideNumberIsInvalid',
        OutsideNumberIsRequired: 'Validation.OutsideNumberIsRequired',
        InnerNumberIsInvalid: 'Validation.InnerNumberIsInvalid',
        InnerNumberIsRequired: 'Validation.InnerNumberIsRequired',
    }
}

export const GoogleApiKey = () => {
    return {
        googleApiKey: "AIzaSyA6pyIkJXN3Bq-zxTZvd4sEFyEjj2bnCFI",
    }
}

export const RegConstants = (function constants() {
    return {
        SecureActionTypeCD: SecureActionTypeCD(),
        LinkTableCD: LinkTableCD(),
        LoginCD: LoginCD(),
        ApprovalStatusCD: ApprovalStatusCD(),
        FormDataMobileCD: FormDataMobileCD(),
        FormDataEmailCD: FormDataEmailCD(),
        PartnerTypeCD: PartnerTypeCD(),
        LanguageCodeCD: LanguageCodeCD(),
        LanguageList: LanguageList(),
        LanguageApiUserInterfaceCD: LanguageApiUserInterfaceCD(),
        DocLoginInterfaceLabels: DocLoginInterfaceLabels(),
        HeaderInterfaceLabels: HeaderInterfaceLabels(),
        FooterInterfaceLabels: FooterInterfaceLabels(),
        ForgotPasswordInterfaceLabels: ForgotPasswordInterfaceLabels(),
        ResetPasswordInterfaceLabels: ResetPasswordInterfaceLabels(),
        LanguageListCD: LanguageListCD(),
        LanguageListEnglish: LanguageListEnglish(),
        AlertsInterfaceLabels: AlertsInterfaceLabels(),
        ValidationInterfaceLabels: ValidationInterfaceLabels(),
        Roles: Roles(),
        TaxProfileInterfaceLabels: TaxProfileInterfaceLabels(),
        RideFilterValueList: RideFilterValueList(),
        CountryListTaxProfile: CountryListTaxProfile(),
        RideOrderStatusCD: RideOrderStatusCD(),
        UserStatusCD: UserStatusCD(),
        Country: Country(),
        GoogleApiKey: GoogleApiKey()
    }
})()