// States
const states = (function states() {
    return {
        ride: [],
        isVeiwTaxProfile: false,
        userLanguageApiLabels: [],
        isPageLoaded: false,
        expandElementId: 0,
        directions: null,
        isShowReceipt: false,
        isVeiwRides: true,
        error: null,
    }
})()
export default states;