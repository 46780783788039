import React, { Component } from 'react';
import './TaxProfile.css';
import "bootstrap/dist/css/bootstrap.min.css";
import states, { taxProfileValidationSchema } from './TaxProfileState';
import { Formik, Field, Form } from 'formik';
import FormikInput from '../../common/formik/FormikInput';
import FormikSelect from '../../common/formik/FormikSelect';
import { RegConstants } from '../../common/registration-constants/RegistrationConstants';
import * as  commonService from '../../../service/common/CommonService';
import * as  rideService from '../../../service/auth/my-rides/MyRides.Service';
import * as commonMethods from '../../common/common-methods/CommonMethods';
import { toast } from 'react-toastify';
import Slide from "@material-ui/core/Slide";
import jwtDecode from 'jwt-decode';

class TaxProfile extends Component {
    constructor(props) {
        super(props);
        this.state = states;
    }

    componentDidMount = () => {
        let result = JSON.parse(localStorage.getItem('languageApiLabels'));
        this.setFormValidations(result);
        this.setState({ userLanguageApiLabels: result, isPageLoaded: true, });
        this.getCustomerInvoice();
    }

    // it sets form validation schema 
    setFormValidations = (masterApiData) => {

        let decodedToken = jwtDecode(localStorage.getItem('token'));
        let mobileValidation = commonMethods.getMinMaxMobileLength(masterApiData, decodedToken.dialcode);
        let validationSchema = taxProfileValidationSchema(masterApiData, mobileValidation.minMobileLength, mobileValidation.maxMobileLength);

        this.setState({ taxProfileValidationSchema: validationSchema, mobileNoMaxLength: mobileValidation.maxMobileLength });
    }

    // It fetches the current invoice being used by the customer
    getCustomerInvoice = () => {
        rideService.getInvoice().then((res) => {
            if (res) {
                this.setState({ previousInvoiceDetail: res }, () => { });
                localStorage.setItem('stateName', res.state);
                localStorage.setItem('cityName', res.town);
                // this.bindCountryList(res);
            }
            else
                this.getCountryList();
        });
    }

    // Retrieves the country list to bind in country dropdown
    getCountryList = () => {
        commonService.getCountryDDO().then(res => {
            let selectedCountry = {};
            if (res) {

                let decodedToken = jwtDecode(localStorage.getItem('token'));
                selectedCountry = res.filter(i => i.uId === decodedToken.countryuid);
                this.getStateList(selectedCountry[0]?.uId);
                this.setState({
                    countryList: [...selectedCountry]
                    // countryList: [...RegConstants.CountryListTaxProfile]
                });
            }
        })
    }

    // Retrieves the state list via country uId
    getStateList = (uId) => {
        commonService.getStateDDO(uId).then(res => {
            if (res) {
                this.setState({
                    stateList: [
                        { value: '', label: this.state.userLanguageApiLabels.filter(i => i.key === 'Login.PleaseSelectLabel')[0].value },
                        ...res]
                })
            }
        })
    }

    // Retrieves the city list according to the state
    onChangeStateAndCityHandler = (label, name) => {
        if (name === 'state') {
            let uId = ""
            if (this.state.stateList.filter(i => i.label === label)[0] !== undefined) {
                uId = this.state.stateList.filter(i => i.label === label)[0].uId;
                this.getCityList(uId)
            }

        }
    }

    // Retrieves the city list according to the stateUId
    getCityList(uId) {
        commonService.getCityDDO(uId).then(res => {
            if (res) {
                this.cityList = res;
                this.setState({
                    cityList: [
                        { value: '', label: this.state.userLanguageApiLabels.filter(i => i.key === 'Login.PleaseSelectLabel')[0].value },
                        ...res
                    ]
                })
            }
        })
    }

    // It displays the all fields required to fill by user in order to create an invoice
    createInvoice = () => {
        this.setState({ isCreateInvoice: true });
    }

    // It cancels creating invoice process
    cancelCreatingInvoice = () => {
        this.setState({ isCreateInvoice: false });
    }

    // It deletes the current invoice according to the uId
    deletePreviousInvoiceDetail = () => {
        rideService.deleteInvoice(this.state.previousInvoiceDetail.uId).then((res) => {
            if (res) {
                toast.success(this.state.userLanguageApiLabels.filter(i => i.key === 'Validation.InvoiceIsDeleted')[0].value);
                let invoiceDetails = this.state.taxProfileState;
                invoiceDetails.name = '';
                invoiceDetails.rfc = '';
                invoiceDetails.businessName = '';
                invoiceDetails.useCFDI = '';
                invoiceDetails.state = '';
                // invoiceDetails.taxResidenceCountry = '';
                // invoiceDetails.numRegldTrib = '';
                invoiceDetails.email = '';
                invoiceDetails.telephone = '';
                invoiceDetails.address = '';
                invoiceDetails.extNumber = '';
                invoiceDetails.intNumber = '';
                invoiceDetails.town = '';
                // invoiceDetails.flat = '';
                // invoiceDetails.building = '';
                invoiceDetails.colony = '';
                invoiceDetails.municipality = '';
                invoiceDetails.zipcode = '';
                invoiceDetails.country = undefined;
                localStorage.removeItem('stateName');
                localStorage.removeItem('cityName');
                this.getCountryList();
                this.setState({ previousInvoiceDetail: {}, isInvoiceDeleted: true, cityList: [] });
            }
        })
    }

    // It sets the previous invoice properties in the state so that user can be able to edit his invoice details
    editPreviousInvoiceDetail = () => {
        let invoice = this.state.taxProfileState;
        let previousInvoiceDetail = this.state.previousInvoiceDetail;

        invoice.name = previousInvoiceDetail.name;
        invoice.rfc = previousInvoiceDetail.rfc;
        invoice.businessName = previousInvoiceDetail.businessName;
        invoice.useCFDI = previousInvoiceDetail.useCFDI;
        // invoice.taxResidenceCountry = previousInvoiceDetail.taxResidenceCountry;
        // invoice.numRegldTrib = previousInvoiceDetail.numRegldTrib;
        invoice.email = previousInvoiceDetail.email;
        invoice.telephone = previousInvoiceDetail.telephone;
        invoice.address = previousInvoiceDetail.address;
        invoice.extNumber = previousInvoiceDetail.extNumber;
        invoice.intNumber = previousInvoiceDetail.intNumber;
        //floor
        invoice.flat = previousInvoiceDetail.flat;
        //edificie
        invoice.building = previousInvoiceDetail.building;
        //suburb
        invoice.colony = previousInvoiceDetail.colony;
        //town
        invoice.municipality = previousInvoiceDetail.municipality;
        // invoice.country = previousInvoiceDetail.country;
        invoice.state = previousInvoiceDetail.state;
        //city
        invoice.town = previousInvoiceDetail.town;
        invoice.zipcode = previousInvoiceDetail.zipcode;
        invoice.uId = previousInvoiceDetail.uId;
        localStorage.setItem('stateName', this.state.previousInvoiceDetail.state);
        localStorage.setItem('cityName', this.state.previousInvoiceDetail.town);

        this.bindCountryList(invoice);
    }

    // It binds the state and city list during the edit scenario
    bindCountryList = (invoice) => {
        commonService.getCountryDDO().then(responseCountry => {
            if (responseCountry) {
                let countryUId = responseCountry.filter(i => i.label === 'Mexico')[0].uId;

                commonService.getStateDDO(countryUId).then(response => {
                    if (response) {
                        let stateUId;
                        if (response.filter(i => i.label === this.state.previousInvoiceDetail.state)[0] !== undefined) {
                            stateUId = response.filter(i => i.label === this.state.previousInvoiceDetail.state)[0].uId;
                        }
                        commonService.getCityDDO(stateUId).then(responseCity => {
                            if (responseCity) {
                                this.setState({
                                    cityList: [
                                        { value: '', label: this.state.userLanguageApiLabels.filter(i => i.key === 'Login.PleaseSelectLabel')[0].value },
                                        ...responseCity
                                    ]
                                })
                                this.setState({
                                    countryList: [...RegConstants.CountryListTaxProfile]
                                }, () => { })

                                this.setState({
                                    stateList: [
                                        { value: '', label: this.state.userLanguageApiLabels.filter(i => i.key === 'Login.PleaseSelectLabel')[0].value },
                                        ...response]
                                })
                                this.setState({ taxProfileState: invoice, isCreateInvoice: true, });
                            }
                        })
                    }
                })
            }
        });
    }

    // If invoice uId will be undefined then it will submit the invoice otherwise it will update the prevoius invoice
    submitTaxProfile = (invoiceDetails) => {
        if (this.state.previousInvoiceDetail.uId === undefined) {
            invoiceDetails.country = 'MEX';
            rideService.saveInvoice(invoiceDetails).then((res) => {
                if (res) {
                    toast.success(this.state.userLanguageApiLabels.filter(i => i.key === 'Validation.InvoiceIsSaved')[0].value);
                    this.setState({ isCreateInvoice: false, isInvoiceDeleted: false });
                    localStorage.removeItem('stateName');
                    localStorage.removeItem('cityName');
                    this.getCustomerInvoice();
                }
            })
        }
        else {
            localStorage.removeItem('stateName');
            localStorage.removeItem('cityName');
            invoiceDetails.uId = this.state.previousInvoiceDetail.uId;
            invoiceDetails.userId = this.state.previousInvoiceDetail.userId;
            invoiceDetails.country = 'MEX';

            rideService.updateInvoice(invoiceDetails).then((res) => {
                if (res) {
                    toast.success(this.state.userLanguageApiLabels.filter(i => i.key === 'Validation.InvoiceIsUpdated')[0].value);
                    this.setState({ isCreateInvoice: false });
                    this.getCustomerInvoice();
                }
            })
        }
    }

    render() {
        const { taxProfileState, taxProfileValidationSchema, taxProfileStateDuringEdit, stateList, cityList, countryList } = this.state;
        return (
            <Formik
                initialValues={this.state.isInvoiceDeleted ? taxProfileStateDuringEdit : taxProfileState}
                enableReinitialize={true}
                validationSchema={taxProfileValidationSchema}
                onSubmit={this.submitTaxProfile}
            >
                {({ setFieldValue, setFieldTouched }) => ((
                    <React.Fragment>
                        {
                            this.state.isCreateInvoice ?
                                <Slide direction="right" timeout={600} in={true} mountOnEnter unmountOnExit>
                                    <Form noValidate>
                                        <div className='tax-profile-div'>
                                            <div className='div-center'>
                                                <div className="input-div">
                                                    <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.Name')[0].value}</label>
                                                    <Field name="name" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.NamePlaceholder')[0].value}
                                                        id="name" type="text" maxLength="50" component={FormikInput} />
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="input-div">
                                                    <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.RFC)[0].value}*</label>
                                                    <Field name="rfc" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.RfcPlaceholder)[0].value}
                                                        id="rfc" type="text" maxLength="13" component={FormikInput} />
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="input-div">
                                                    <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.CompanyName)[0].value}*</label>
                                                    <Field name="businessName" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.CompanyNamePlaceHolder)[0].value}
                                                        id="businessName" type="text" maxLength="100" component={FormikInput} />
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="input-div">
                                                    <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.CFDI)[0].value}*</label>
                                                    <Field name="useCFDI" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.CFDIPlaceholder)[0].value}
                                                        id="useCFDI" type="text" maxLength="100" component={FormikInput} />
                                                </div>
                                            </div>
                                            {/* <div className='div-center'>
                                                <div className="input-div">
                                                    <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.ResidentialTax)[0].value}*</label>
                                                    <Field name="taxResidenceCountry" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.ResidentialTaxPlcaholder)[0].value}
                                                        id="taxResidenceCountry" type="text" maxLength="100" component={FormikInput} />
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="input-div">
                                                    <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.TaxIdNumber)[0].value}</label>
                                                    <Field name="numRegldTrib" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.TaxIdNumberPlaceholder)[0].value}
                                                        id="numRegldTrib" type="text" maxLength="100" component={FormikInput} />
                                                </div>
                                            </div> */}
                                            <div className='div-center'>
                                                <div className="input-div">
                                                    <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.EmailLabel)[0].value}*</label>
                                                    <Field name="email" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.EmailLabelPlaceHolder)[0].value}
                                                        id="email" type="text" maxLength="50" component={FormikInput} />
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="input-div">
                                                    <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.PhoneNumber)[0].value}*</label>
                                                    <Field name="telephone" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.PhoneNumberPlaceholder)[0].value}
                                                        id="telephone" type="text" maxLength={this.state.mobileNoMaxLength} component={FormikInput} />
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="input-div">
                                                    <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.Address)[0].value}*</label>
                                                    <Field name="address" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.TaxProfileInterfaceLabels.AddressPlaceholder)[0].value}
                                                        id="address" type="text" maxLength="100" component={FormikInput} />
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="d_flex">
                                                    <div className="input-div inputs-labels-row-section">
                                                        <label className="label">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.OutsideNumber')[0].value}*</label>
                                                        <Field name="extNumber" id="extNumber" type="text" maxLength="50" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.OutsideNumberPlaceholder')[0].value} component={FormikInput} />
                                                    </div>
                                                    <div className="input-div inputs-labels-row-section left">
                                                        <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.InnerNumber')[0].value}</label>
                                                        <Field name="intNumber" id="intNumber" type="text" maxLength="50" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.InnerNumberPlaceholder')[0].value} component={FormikInput} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="d_flex">
                                                    <div className="input-div inputs-labels-row-section">
                                                        <label className="label">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.Floor')[0].value}</label>
                                                        <Field name="flat" id="flat" type="text" maxLength="50" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.FloorPlaceholder')[0].value} component={FormikInput} />
                                                    </div>
                                                    <div className="input-div inputs-labels-row-section left">
                                                        <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.Edificie')[0].value}</label>
                                                        <Field name="building" id="building" type="text" maxLength="50" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.EdificiePlaceholder')[0].value} component={FormikInput} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="d_flex">
                                                    <div className="input-div inputs-labels-row-section">
                                                        <label className="label">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.Suburb')[0].value}*</label>
                                                        <Field name="colony" id="colony" type="text" maxLength="50" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.SuburbPlaceholder')[0].value} component={FormikInput} />
                                                    </div>
                                                    <div className="input-div inputs-labels-row-section left">
                                                        <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.TownMunicipality')[0].value}*</label>
                                                        <Field name="municipality" id="municipality" type="text" maxLength="50" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.TownMunicipalityPlaceholder')[0].value} component={FormikInput} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="d_flex">
                                                    <div className="input-div inputs-labels-row-section">
                                                        <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.Country')[0].value}</label>
                                                        <Field name="country" id="country" option={countryList} component={FormikSelect} onChange={this.onChangeStateAndCityHandler} />
                                                    </div>
                                                    <div className="input-div inputs-labels-row-section left">
                                                        <label className="label">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.State')[0].value}</label>
                                                        <Field name="state" id="state" type="select" option={stateList} component={FormikSelect} onChange={this.onChangeStateAndCityHandler} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='div-center'>
                                                <div className="d_flex">
                                                    <div className="input-div inputs-labels-row-section">
                                                        <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.City')[0].value}</label>
                                                        <Field name="town" id="town" option={cityList} component={FormikSelect} onChange={this.onChangeStateAndCityHandler} />
                                                    </div>
                                                    <div className="input-div inputs-labels-row-section left">
                                                        <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.PostalCode')[0].value}*</label>
                                                        <Field name="zipcode" id="zipcode" type="text" maxLength="6" onKeyDown={commonMethods.updateNumber} placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.PostalCodePlaceholder')[0].value} component={FormikInput} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='div-center'>
                                        <div className="d_flex">
                                            <div className="input-div inputs-labels-row-section">
                                                <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.PaymentMethod')[0].value}*</label>
                                                <Field name="paymentMethod" id="paymentMethod" type="text" maxLength="50" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.PaymentMethodPlaceholder')[0].value} component={FormikInput} />
                                            </div>
                                            <div className="input-div inputs-labels-row-section left">
                                                <label className="label"> {this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.InvoiceSettings')[0].value}*</label>
                                                <Field name="invoiceSetting" id="invoiceSetting" type="text" maxLength="50" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.InvoiceSettingPlaceholder')[0].value} component={FormikInput} />
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='save-buttons-div'>
                                                <button className='save-button' type='submit' >{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.SaveInvoiceButton')[0].value}</button>
                                                <button className='cancel-button' onClick={() => this.cancelCreatingInvoice()} >{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.CancelInvoiceButton')[0].value}</button>
                                            </div>
                                        </div>
                                    </Form>
                                </Slide >
                                :
                                <div className="create-invoice-div">
                                    {
                                        this.state.previousInvoiceDetail.uId === undefined ?
                                            <div>
                                                <button type='button' className="create-invoice-button" onClick={() => this.createInvoice()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.CreateInvoice')[0].value}</button>
                                                <hr />
                                            </div>
                                            :
                                            <div>
                                                <hr />
                                                <div className='edit-delete-div'>
                                                    <label>{this.state.previousInvoiceDetail.businessName}</label>
                                                    <div className='edit-delete-button'>
                                                        <button type='button' onClick={() => this.editPreviousInvoiceDetail()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.EditButton')[0].value}</button>
                                                        <button type='button' className="delete-button" onClick={() => this.deletePreviousInvoiceDetail()}>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === 'TaxProfile.DeleteButton')[0].value}</button>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                        }
                    </React.Fragment>
                ))}
            </Formik>
        )
    }
}

export default TaxProfile;