import React from 'react';
import { ErrorMessage } from 'formik';

const ReactstarpFormikInput = (
    {
        field: { ...fields },
        form: { touched, errors, setFieldValue, setFieldinpuValue, ...rest },
        ...props
    }) => (
    <React.Fragment>
        <input {...props} {...fields} className={'form-control' + (touched[fields.name] && errors[fields.name] ? ' is-invalid' : '')}
            style={{ backgroundImage: 'none' }} maxLength={props.maxLength}
            onChange={(event) => {
                setFieldValue(fields.name, event.target.value);
                { (fields.name === 'mobileNumber' || fields.name === 'userPwd' || fields.name === 'userName') && props.onChange(event.target.value, fields.name); }
            }}
        ></input>
        <ErrorMessage name={[fields.name]} component="div" className="invalid-feedback" style={{ textAlign: 'left' }} />
    </React.Fragment>
);

export default ReactstarpFormikInput;

