// States
const otpState = (function otpStates() {
    return {
        mobileOtp: '',
        isMobileOtpError: false,
        mobileNo: '',
        verifyOtpApiLabels: [],
        isPageLoaded: false,
        isMobileOtpCorrect: false
    }
})()
export default otpState;