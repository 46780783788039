import React, { Component } from "react";
import './RideDetails.css';
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer
} from "react-google-maps";
import originIcon from '../../../asset/attachment-images/origin.png'
import destinationIcon from '../../../asset/attachment-images/destination.png'

const customGoogleMapStyle = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#dfeaf0" }, { "saturation": "-26" }, { "lightness": "-54" }, { "gamma": "0.95" }]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{ "color": "#dfeaf0" }, { "lightness": "35" }, { "gamma": "1.16" }]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.icon",
        "stylers": [{ "lightness": "35" }, { "gamma": "1.16" }]
    },
    {
        "featureType": "landscape.natural.terrain",
        "elementType": "all",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#8799a3" }, { "lightness": "-15" }, { "saturation": "29" }, { "gamma": "1.00" }]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [{ "hue": "#0063ff" }, { "saturation": "-35" }, { "lightness": "26" }, { "gamma": "0.90" }]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{ "color": "#8fe038" }, { "lightness": "55" }, { "gamma": "1.00" }]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.icon",
        "stylers": [{ "lightness": "35" }, { "gamma": "1.16" }]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{ "saturation": -100 }, { "lightness": 45 }]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{ "color": "#dfeaf0" }, { "lightness": "-15" }, { "gamma": "0.70" }]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{ "color": "#78d4ff" }, { "visibility": "on" }, { "lightness": "40" }, { "gamma": "1.08" }]
    }
]

let origin;
let destination;

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            directions: null,
        };
    }

    componentDidMount() {
        origin = { lat: Number(localStorage.getItem('pickupLat')), lng: Number(localStorage.getItem('pickupLon')) };
        destination = { lat: Number(localStorage.getItem('dropLat')), lng: Number(localStorage.getItem('dropLon')) };

        const directionsService = new window.google.maps.DirectionsService();

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    console.log(result)
                    this.setState({
                        directions: result
                    });

                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    }

    render() {
        const GoogleMapExample = withGoogleMap(props => (
            <GoogleMap
                defaultCenter={{ lat: 6.5244, lng: 3.3792 }}
                defaultZoom={8}
                options={{
                    styles: customGoogleMapStyle,
                }}
            >
                <Marker
                    icon={originIcon}
                    position={origin}>
                </Marker>
                <Marker
                    icon={destinationIcon}
                    position={destination}>
                </Marker>
                <DirectionsRenderer
                    directions={this.state.directions}
                    options={{
                        polylineOptions: {
                            strokeColor: '#2967e7',
                        },
                        suppressMarkers: true
                    }}
                />
            </GoogleMap>
        ));

        return (
            <div>
                <GoogleMapExample
                    // containerElement={<div style={{ height: `500px` }} />}
                    containerElement={<div className="map-height" />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </div>
        );
    }
}

export default Map;