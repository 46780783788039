import React from 'react';
import resetPasswordState, { formValidationSchema } from './ResetPassword.state';
import { Formik, Field, Form } from 'formik';
import FormikInput from '../../../common/formik/FormikInput';
import * as loginService from '../../../../service/auth/login/Login.Service';
import { toast } from 'react-toastify';
import '../ForgotPassword.css';
import * as commonMethods from '../../../common/common-methods/CommonMethods';
import Red from '../../../../asset/attachment-images/Red.png'
import Yellow from '../../../../asset/attachment-images/Yellow.png'
import Green from '../../../../asset/attachment-images/Green.png'
import ReactTooltip from 'react-tooltip';
import { RegConstants } from '../../../common/registration-constants/RegistrationConstants';
import VooyHeader from '../../../../asset/headerimages/VooyHeader.png'

let isOtpResent = false;

class ResetPassword extends React.Component {
    state = resetPasswordState

    componentDidMount() {
        if (!this.props.location.otpuId) {
            this.props.history.goBack();
        }
        else { 
            let result = JSON.parse(localStorage.getItem('languageApiLabels'));
            let validationSchema = formValidationSchema(result);
    
            this.setState({
                userLanguageApiLabels: result, isPageLoaded: true,
                formValidationSchema: validationSchema,
            });
        }
    }

    // It changes the password for that particular email
    resetPassword = (userDetails) => {
        let formData = new FormData();
        let languageCode = localStorage.getItem('languageCode');

        formData.append('languageCode', languageCode);
        formData.append('otp_uid', this.props.location.otpuId);
        formData.append('sms_otp', "");
        formData.append('email_otp', userDetails.otp);
        formData.append('new_password', userDetails.userPwd);
        formData.append('secureActionTypeCD', RegConstants.SecureActionTypeCD.forgotPassword);

        loginService.resetPassword(formData).then(res => {
            if (res.data) {
                this.props.history.push({
                    pathname: '/login-user-email'
                });
                toast.success(this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ValidationInterfaceLabels.PasswordIsChanged)[0].value);
            }
        })
    }

    // It checks the strength of the password and displays it
    onchangePasswordHandler = (password) => {
        if (password.length >= 6) {
            let passwordStrength = commonMethods.checkPasswordStrength(password);
            this.setState({
                passwordStrength: passwordStrength,
                isPasswordStrength: true
            });
        }
        else
            this.setState({ passwordStrength: {}, isPasswordStrength: false });
    }

    // It resends the otp and retrieves a new otpuId
    onReSendOTP = () => {
        loginService.forgotPassword(this.props.location.formData).then(res => {
            if (res.data) {
                this.props.location.otpuId = res.data.uId;
                isOtpResent = true;
                this.setState({ isOtpResent: true })
                toast.success(this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ValidationInterfaceLabels.OtpResendSuccussfully)[0].value);
            }
        })
    }

    handleOtpResendState = () => {
        isOtpResent = false;
    }

    render() {
        const { userDetails, formValidationSchema } = this.state;
        return (
            <Formik
                initialValues={userDetails}
                validationSchema={formValidationSchema}
                onSubmit={this.resetPassword}
            >
                {({ setFieldValue, setFieldTouched }) => (
                    <React.Fragment>
                        <div className="Header_Section">
                            <div className="login-ride-logo-div">
                                <img className="vooyLogo_Header" src={VooyHeader} alt="VooyRidePsrtner" />
                            </div>
                        </div>
                        <div className="main_section">
                            <div className="sub-main-section-otp">
                                <div className="sub_main_form_section">
                                    <h2 className="heading-confirm-password">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === "ResetPassword.ResetPasswordHeader")[0].value}</h2>
                                    <Form noValidate>
                                        <div className="form-group">
                                            <label className="label_userdetail">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ResetPasswordInterfaceLabels.EnterOTPLabel)[0].value}</label>
                                            {isOtpResent ? setFieldValue('otp', '', false) && setFieldTouched('otp', false) : ""}
                                            <Field name="otp" id="otp" type="text" maxLength="6" placeholder={this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ResetPasswordInterfaceLabels.EnterOTPPlaceholder)[0].value} onKeyDown={commonMethods.updateNumber} component={FormikInput} />
                                            {this.handleOtpResendState()}
                                        </div>
                                        <div className={this.state.isPasswordStrength ? "" : "form-group"}>
                                            <label className="label_userdetail">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ResetPasswordInterfaceLabels.NewPasswordLabel)[0].value}*</label>
                                            <div className="password-section">
                                                <Field name="userPwd" placeholder="***********" id="userPwd" type="password" maxLength="15" onChange={(event) => this.onchangePasswordHandler(event)} component={FormikInput} />
                                                <i className="fa fa-eye" onClick={commonMethods.showHidePassword}></i>
                                            </div>
                                            {
                                                this.state.isPasswordStrength &&
                                                <span className={this.state.passwordStrength.className}>
                                                    {
                                                        this.state.passwordStrength.strength === "Excellent" ?
                                                            <div className='password-strength-bar'>
                                                                <div className='password-tooltip-div'>
                                                                    {this.state.userLanguageApiLabels.filter(i => i.key === "Validation.PasswordStrengthExcellent")[0].value}
                                                                    <i className="fas fa-info-circle password-tip-icon strong-password" data-tip data-for='excellentPassword' ></i>
                                                                    <ReactTooltip className='password-tooltip-width' id='excellentPassword' aria-haspopup='true' type='success' place="top" multiline={true} effect="float">
                                                                        {this.state.userLanguageApiLabels.filter(i => i.key === "Validation.PasswordStrengthExcellentToolTip")[0].value}
                                                                    </ReactTooltip>
                                                                </div>
                                                                <img src={Green} alt="Excellent Password" width="100%" />
                                                            </div>
                                                            :
                                                            this.state.passwordStrength.strength === "Medium" ?
                                                                <div className='password-strength-bar'>
                                                                    <div className='password-tooltip-div'>
                                                                        {this.state.userLanguageApiLabels.filter(i => i.key === "Validation.PasswordStrengthMedium")[0].value}
                                                                        <i className="fas fa-info-circle password-tip-icon medium-password" data-tip data-for='mediumPassword' ></i>
                                                                        <ReactTooltip className='password-tooltip-width' id='mediumPassword' aria-haspopup='true' type='info' place="top" multiline={true} effect="float">
                                                                            {this.state.userLanguageApiLabels.filter(i => i.key === "Validation.PasswordStrengthMediumToolTip")[0].value}
                                                                        </ReactTooltip>
                                                                    </div>
                                                                    <img src={Yellow} alt="Medium Password" width="100%" />
                                                                </div>
                                                                :
                                                                <div className='password-strength-bar'>
                                                                    <div className='password-tooltip-div'>
                                                                        {this.state.userLanguageApiLabels.filter(i => i.key === "Validation.PasswordStrengthLow")[0].value}
                                                                        <i className="fas fa-info-circle password-tip-icon low-password" data-tip data-for='lowPassword' ></i>
                                                                        <ReactTooltip className='password-tooltip-width' id='lowPassword' aria-haspopup='true' type='error' place="top" multiline={true} effect="float">
                                                                            {this.state.userLanguageApiLabels.filter(i => i.key === "Validation.PasswordStrengthLowToolTip")[0].value}
                                                                        </ReactTooltip>
                                                                    </div>
                                                                    <img src={Red} alt="Low Password" width="100%" />
                                                                </div>
                                                    }
                                                </span>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label className="label_userdetail">{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ResetPasswordInterfaceLabels.ConfirmPasswordLabel)[0].value}*</label>
                                            <div className="password-section">
                                                <Field name="confirmPassword" placeholder="***********" id="confirmPassword" type="password" maxLength="15" component={FormikInput} />
                                                <i className="fa fa-eye" onClick={commonMethods.showHideConfirmPassword}></i>
                                            </div>
                                        </div>
                                        <div className="change_pass_button">
                                            <div className="forget_button_div forget_button_width"><button type="submit" >{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ResetPasswordInterfaceLabels.ChangePasswordButton)[0].value}</button> </div>
                                            <div className="resend-otp" onClick={this.onReSendOTP}>
                                                <label>{this.state.isPageLoaded && this.state.userLanguageApiLabels.filter(i => i.key === RegConstants.ResetPasswordInterfaceLabels.ResendOtpLink)[0].value}</label>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>)
                }
            </Formik>
        );
    }
}

export default ResetPassword;